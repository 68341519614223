// useLoginTransaction.ts
import { useEffect, useState } from "react";
import { DeviceApi, Credential, Transaction } from "../../API/XFA_DEVICE_API";
import { deviceApiConfig } from "../../Config";
import { useClientApiContext } from "../../Extension/ClientApiContext";

interface UseLoginTransactionProps {
  transaction: Transaction | undefined;
  error: string | undefined;
  setError: (error: string | undefined) => void;
  setPendingAction: (state: boolean) => void;
  redirectToApplication: (transaction: Transaction) => void;
  setWaitForConfirmation: (waitForConfirmation: boolean) => void;
}

export const useLoginTransaction = ({
  transaction,
  error,
  setError,
  setPendingAction,
  redirectToApplication,
  setWaitForConfirmation,
}: UseLoginTransactionProps) => {
  const [loginCalled, setLoginCalled] = useState(false);
  const { clientApi, isExtensionInstalled } = useClientApiContext();
  const deviceClient = new DeviceApi(deviceApiConfig);
  deviceClient.request.config.CREDENTIALS = "include";
  deviceClient.request.config.WITH_CREDENTIALS = true;

  useEffect(() => {
    const handleLoginTransaction = async () => {
      if (!transaction || error || loginCalled) return;
      if (
        transaction.status === Transaction.status.UNSUPPORTED ||
        transaction.status === Transaction.status.SKIPPED
      ) {
        return;
      }
      // Do not handle login transaction if MFA is not confirmed on AlwaysVerify
      if (
        transaction.decisions?.mfa?.AlwaysVerify === true &&
        transaction.decisions?.mfa?.Confirmed !== true
      ) {
        return;
      }
      setLoginCalled(true);

      const credentialID = await clientApi
        .getCredentialID(transaction.transactionId)
        .catch((err) => {
          setPendingAction(false);
          return;
        });

      // Should only be used with extension
      // TODO: Remove when extension is removed
      try {
        const hasValidCredential =
          transaction.decisions?.mfa?.credentials?.credentials?.some(
            (credential: Credential) => credentialID === credential.id,
          );

        if (!hasValidCredential) {
          setPendingAction(false);
          return;
        }

        const loginBeginResponse = await deviceClient.default.postLoginBegin(
          transaction.transactionId,
        );
        const loginCredential =
          await clientApi.getCredential(loginBeginResponse);
        await deviceClient.default.postLoginFinish(
          loginBeginResponse.SessionId!,
          transaction.transactionId,
          loginCredential,
        );
        const updatedTransaction = await deviceClient.default.getToken(
          transaction.transactionId,
        );

        if (updatedTransaction?.decisions?.mfa?.status === "OK") {
          redirectToApplication(updatedTransaction);
        } else {
          const errorMessage =
            updatedTransaction?.decisions?.mfa?.error || "Something went wrong";
          setError(errorMessage);
          setWaitForConfirmation(false);
          setPendingAction(false);
        }
      } catch (err: any) {
        console.error("Error during login transaction:", err);
        setError(err.message || "An unexpected error occurred");
        setPendingAction(false);
        setWaitForConfirmation(false);
      }
    };

    handleLoginTransaction();
  }, [
    transaction,
    error,
    loginCalled,
    setError,
    clientApi,
    setPendingAction,
    isExtensionInstalled,
    deviceClient.default,
    redirectToApplication,
    setWaitForConfirmation,
  ]);
};
