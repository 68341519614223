import React from "react";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import XFAIcon from "../../../images/XFA_woordmerk_donkergrijs.svg";
import ArrowsIcon from "../../../images/connect_arrows.png";
import OrganizationFallbackIcon from "../../../images/connect_fallback_organization.png";
import PrivacyRuleButton from "../../General/PrivacyRuleButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AppIcon from "../../../images/General_XFA_app_icon.png";
import { capitalizeFirstLetter } from "../../System/System";
import "../../General/general.css";

interface ConnectSectionProps {
  goToInstallation: () => void;
  organization: string | undefined;
  organizationIconUrl: string | undefined;
  mobile: boolean;
  invited: boolean;
  isSkippable: boolean | undefined;
  recheck: () => void;
  skip?: () => void;
  demo?: boolean;
}

export const ConnectSection: React.FC<ConnectSectionProps> = ({
  goToInstallation,
  organization,
  organizationIconUrl,
  mobile,
  invited,
  isSkippable,
  recheck,
  skip,
  demo,
}) => {
  const { t } = useTranslation();

  return (
    <div className="general">
      <div className="images-container">
        <img
          src={
            organizationIconUrl ? organizationIconUrl : OrganizationFallbackIcon
          }
          alt="connect-affiliation"
          className="image"
        />
        <img
          src={ArrowsIcon}
          alt="connect-affiliation"
          className="image-arrows"
        />
        <img src={XFAIcon} alt="connect-XFA" className="image" />
      </div>
      <Typography className="header">
        {invited
          ? t("Installation.connectHeaderInvited", {
              organization:
                organization ?? capitalizeFirstLetter(t("Token.organization")),
            })
          : demo
            ? t("Installation.connectHeaderDemo")
            : t("Installation.connectHeader", {
                organization:
                  organization ??
                  capitalizeFirstLetter(t("Token.organization")),
              })}
      </Typography>
      <Typography variant="body1" className="information">
        {invited
          ? t("Installation.connectDescriptionInvited")
          : t("Installation.connectDescription")}
      </Typography>
      <div className="information-container">
        <PrivacyRuleButton iconName="privacy" />
        <PrivacyRuleButton iconName="remote-control" />
        <PrivacyRuleButton iconName="time" />
      </div>
      {/* TODO: Show when privacy policy is available */}
      {/* <div className="privacy-policy-container">
        <Typography
          variant="body1"
          component="a"
          className="privacy-policy"
          href="jouwLinkURL"
        >
          {t("Installation.securityPolicy", { organization: organization ?? t("Token.organization") })}
        </Typography>
      </div> */}
      {mobile && (
        <div className="button-container">
          <Button
            startIcon={
              <img className="smallIcon" src={AppIcon} alt="Ongoing" />
            }
            className="icon-button"
            onClick={goToInstallation}
            endIcon={<ChevronRightIcon />}
          >
            <Typography className="install-text">
              {t("Installation.install")}
            </Typography>
          </Button>
        </div>
      )}
      {!mobile && (
        <div className="button-container">
          <Button
            size="large"
            variant="contained"
            className="black-button"
            onClick={goToInstallation}
          >
            <Typography className="install-text">
              {t("Installation.connectButton")}
            </Typography>
          </Button>
        </div>
      )}
      {isSkippable === true && skip && (
        <div className="skip-button-container">
          <Button className="skip-button" onClick={skip} size="small">
            <Typography className="skipText">{t("Token.skip")}</Typography>
          </Button>
        </div>
      )}
      {!mobile && (
        <div className="button-container" style={{ marginTop: "3rem" }}>
          <Button
            className="double-text-button"
            onClick={recheck}
            endIcon={<ChevronRightIcon />}
          >
            <div className="button-text">
              <Typography className="title">
                {t("Installation.alreadyInstalledTitle")}
              </Typography>
              <Typography className="description">
                {t("Installation.alreadyInstalledDescription")}
              </Typography>
            </div>
          </Button>
        </div>
      )}
    </div>
  );
};
