import React, { useState, useEffect } from "react";
import "./Home.css";

import { useTranslation } from "react-i18next";

import { Button, Menu, MenuItem } from "@mui/material";

import CssBaseline from "@mui/material/CssBaseline";

import Installation from "../Installation/Installation";
import { Route, Routes, Navigate } from "react-router-dom";

import Invitations from "../Invitations/Invitations";

import NavigationArrowDownIcon from "../../images/nav-arrow-down.svg";

import Token from "../Token/Token";

import * as Sentry from "@sentry/react";
import Mfa from "../Mfa/Mfa";
import ConfirmMfa from "../Mfa/ConfirmMfa";
import Verify from "../Verify/Verify";
import MfaExtension from "../Mfa/Mfa_Extension";
import { isBraveOrSafari } from "../System/System";

export interface HomeProps {
  navigator: any;
  browser: any;
}

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const Home: React.FC<HomeProps> = (props: HomeProps) => {
  const { i18n } = useTranslation();

  const languageNames: { [key: string]: string | undefined } = {
    nl: "Nederlands",
    en: "English",
    fr: "Français",
    de: "Deutsch",
    es: "Español",
  };

  // Takes 'en' from 'en-GB'
  const normalizeLanguageCode = (lng: string) => {
    const normalized = lng.split("-")[0];
    return Object.keys(languageNames).includes(normalized) ? normalized : "en";
  };

  const [currentLanguage, setCurrentLanguage] = useState(
    normalizeLanguageCode(i18n.language),
  );

  useEffect(() => {
    const onLanguageChanged = (lng: string) => {
      setCurrentLanguage(lng);
    };

    i18n.on("languageChanged", onLanguageChanged);

    return () => {
      i18n.off("languageChanged", onLanguageChanged);
    };
  }, [i18n]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (lng: string) => {
    i18n.changeLanguage(lng);
    handleClose();
  };
  const braveOrSafari = isBraveOrSafari(navigator);

  return (
    <>
      <CssBaseline />
      <div className="overview" data-cy="overview">
        <div className="container">
          <SentryRoutes>
            <Route
              path="/"
              element={
                <Installation
                  navigator={props.navigator}
                  browser={props.browser}
                />
              }
            />
            <Route
              path="/token"
              element={
                <Token navigator={props.navigator} browser={props.browser} />
              }
            />
            <Route
              path="/token-mobile"
              element={
                <Token navigator={props.navigator} browser={props.browser} />
              }
            />
            <Route
              path="/mfa"
              element={
                !braveOrSafari ? (
                  <MfaExtension
                    navigator={props.navigator}
                    browser={props.browser}
                  />
                ) : (
                  <Mfa navigator={props.navigator} browser={props.browser} />
                )
              }
            />
            <Route
              path="/confirm-mfa"
              element={
                <ConfirmMfa
                  navigator={props.navigator}
                  browser={props.browser}
                />
              }
            />
            <Route
              path="/installation"
              element={
                <Installation
                  navigator={props.navigator}
                  browser={props.browser}
                />
              }
            />
            <Route
              path="/invitation"
              element={
                <Invitations
                  navigator={props.navigator}
                  browser={props.browser}
                />
              }
            />
            <Route
              path="/verify"
              element={
                <Verify navigator={props.navigator} browser={props.browser} />
              }
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </SentryRoutes>
        </div>
        <div className="footer">
          <div className="languages">
            <Button
              aria-controls="language-menu"
              aria-haspopup="true"
              onClick={handleClick}
              className={`languageButton ${
                i18n.language === "nl" ? "active" : ""
              }`}
              endIcon={
                <img
                  alt="downIcon"
                  src={NavigationArrowDownIcon}
                  className={anchorEl ? "rotateIcon" : "icon"}
                />
              }
            >
              {languageNames[currentLanguage]}
            </Button>
            <Menu
              id="language-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              className="languageMenu"
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            >
              {Object.entries(languageNames).map(([code, name]) => (
                <MenuItem
                  key={code}
                  className="languageMenuItem"
                  selected={i18n.language === code}
                  onClick={() => handleLanguageChange(code)}
                >
                  {name}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
