// useDesktopTransactionStatusHandler.ts
// TODO: Rename to useTransactionStatusHandler.ts after removing the extension
import { useEffect } from "react";
import { Transaction } from "../../API/XFA_DEVICE_API";

interface useDesktopTransactionStatusHandlerProps {
  error: string | undefined;
  waitForConfirmation: boolean;
  transaction: Transaction | undefined;
  handleRedirectToApplication: (transaction: Transaction) => void;
  setError: (error: string | undefined) => void;
}

export const useDesktopTransactionStatusHandler = ({
  error,
  waitForConfirmation,
  transaction,
  handleRedirectToApplication,
  setError,
}: useDesktopTransactionStatusHandlerProps) => {
  useEffect(() => {
    if (error !== undefined || error === "") return;
    if (transaction?.decisions?.mfa?.status === "OK") {
      handleRedirectToApplication(transaction);
    } else if (
      waitForConfirmation &&
      transaction?.decisions?.mfa?.status === "BLOCKING" &&
      transaction?.decisions?.mfa?.error !== ""
    ) {
      setError(transaction?.decisions?.mfa?.error);
    }
  }, [
    error,
    transaction,
    waitForConfirmation,
    handleRedirectToApplication,
    setError,
  ]);
};
