import React from "react";
import {
  // eslint-disable-next-line camelcase
  _token__transactionId__post_request,
  DeviceApi,
} from "../API/XFA_DEVICE_API";
import { deviceApiConfig } from "../Config";
import { ErrorSection } from "../Token/sections/ErrorSection";
import useTransaction from "../Token/hooks/useTransaction";
// eslint-disable-next-line camelcase
import action = _token__transactionId__post_request.action;
import XFAIcon from "../../images/XFA_woordmerk_donkergrijs.svg";
import "../General/general.css";
import "./Mfa.css";
import TopBar from "../General/TopBar";
import { MfaHeader } from "./widgets/MfaHeader";
import { MfaButtons } from "./widgets/MfaButtons";
import { MfaWaitForConfirmation } from "./widgets/MfaWaitForConfirmation";
import { useTransactionPolling } from "./hooks/useTransactionPolling";
import { useRedirectToApplication } from "./hooks/useRedirectToApplication";
import { useDesktopTransactionStatusHandler } from "./hooks/useDesktopTransactionStatusHandler";
import { useSendMfa } from "./hooks/useSendMfa";
import { useTrustDevice } from "./hooks/useTrustDevice";

export interface MfaProps {
  navigator: any;
  browser: any;
}

const Mfa: React.FC<MfaProps> = (props: MfaProps) => {
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [isWaitingForConfirmation, setIsWaitingForConfirmation] =
    React.useState<boolean>(false);
  const [isWaitingForEmailConfirmation, setIsWaitingForEmailConfirmation] =
    React.useState<boolean>(false);
  const { transaction, transactionError, updateTransaction } = useTransaction(
    props.navigator,
  );
  const [pending, setPending] = React.useState<boolean>(true);
  const [didTapTrustButtons, setDidTapTrustButtons] =
    React.useState<boolean>(false);
  const deviceClient = new DeviceApi(deviceApiConfig);
  deviceClient.request.config.CREDENTIALS = "include";
  deviceClient.request.config.WITH_CREDENTIALS = true;

  const { handleRedirectToApplication } = useRedirectToApplication({
    setError,
  });
  const isFirstMfaDeviceOrOnlyOwnDevice =
    transaction?.decisions?.mfa?.credentials?.credentials === undefined ||
    transaction?.decisions?.mfa?.credentials?.credentials.filter((cred) => {
      return cred.deviceId !== transaction?.device_id;
    }).length === 0;
  const isFirstMfaDevice =
    transaction?.decisions?.mfa?.credentials?.credentials === undefined ||
    transaction?.decisions?.mfa?.credentials?.credentials?.length === 0;

  useTransactionPolling(
    transaction,
    error,
    isWaitingForConfirmation,
    pending,
    updateTransaction,
  );

  useDesktopTransactionStatusHandler({
    error,
    waitForConfirmation: isWaitingForConfirmation,
    transaction,
    handleRedirectToApplication,
    setError,
  });

  React.useEffect(() => {
    if (transaction === undefined) {
      return;
    }
    if (
      transaction.status === "SKIPPED" ||
      transaction.status === "GRANTED" ||
      transaction.status === "UNSUPPORTED"
    ) {
      setIsWaitingForConfirmation(true);
      setPending(true);
      return;
    }
    if (
      transaction.decisions === undefined ||
      transaction.decisions.mfa === undefined ||
      transaction.decisions.mfa.MfaCredIdFoundOnDevice === undefined
    ) {
      return;
    }
    if (transaction.decisions.mfa.AlwaysVerify === true) {
      // AlwaysVerify flow
      if (transaction.decisions.mfa.autoApprove === true && isFirstMfaDevice) {
        setIsWaitingForConfirmation(true);
        setPending(true);
      } else {
        setIsWaitingForConfirmation(
          didTapTrustButtons ||
            transaction.decisions.mfa.MfaCredIdFoundOnDevice === true,
        );
        setPending(
          didTapTrustButtons ||
            transaction.decisions.mfa.MfaCredIdFoundOnDevice !== true,
        );
      }
    } else if (transaction.decisions.mfa.MfaCredIdFoundOnDevice === true) {
      // login flow
      setIsWaitingForConfirmation(false);
      setPending(true);
    } else {
      // register flow
      setIsWaitingForConfirmation(didTapTrustButtons);
      setPending(didTapTrustButtons);
    }
  }, [transaction, didTapTrustButtons, isFirstMfaDevice]);

  React.useEffect(() => {
    if (transactionError) {
      setError(transactionError);
    }
  }, [transactionError]);

  useSendMfa({
    transaction,
    isWaitingForConfirmation,
    setIsWaitingForConfirmation,
    setError,
    deviceClient,
    isFirstMfaDevice,
  });

  const handleTrustDevice = useTrustDevice({
    transaction,
    setIsWaitingForConfirmation,
    setError,
    deviceClient,
  });

  return (
    <div className="root">
      <TopBar transaction={null} signIn={false} />
      <div className="content-container">
        <div className="content-token">
          <div className="general">
            <img src={XFAIcon} alt="XFAIcon" className="xfaIcon" />
            <MfaHeader
              waitForConfirmation={isWaitingForConfirmation}
              pending={pending}
            />
            <div className="general">
              {error !== undefined ? (
                <ErrorSection error={error} />
              ) : isWaitingForConfirmation ? (
                <MfaWaitForConfirmation
                  waitForConfirmation={isWaitingForConfirmation}
                  waitForEmailConfirmation={isWaitingForEmailConfirmation}
                  firstMfaDevice={isFirstMfaDeviceOrOnlyOwnDevice}
                  onSendEmail={() => {
                    setIsWaitingForConfirmation(true);
                    setIsWaitingForEmailConfirmation(true);
                    deviceClient.default.postToken1(
                      transaction?.transactionId!,
                      {
                        action: action.SEND_MFA,
                      },
                      undefined,
                      undefined,
                      undefined,
                      true,
                    );
                  }}
                />
              ) : (
                !pending && (
                  <MfaButtons
                    onYesClick={() => {
                      setDidTapTrustButtons(true);
                      handleTrustDevice();
                    }}
                    onNoClick={() => {
                      setDidTapTrustButtons(true);
                      setIsWaitingForConfirmation(true);
                    }}
                  />
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mfa;
