import {
  Card,
  CardActionArea,
  CardContent,
  Link,
  Typography,
} from "@mui/material";
import React from "react";
import { setupDeferredDeepLinkForLatestInvitation } from "../../Deeplinks/Deeplinks";
import { useTranslation } from "react-i18next";

//Icons
import DesktopAccessDisabledIcon from "@mui/icons-material/DesktopAccessDisabled";
import AppleIcon from "../../../images/apple.png";
import WindowsIcon from "../../../images/windows.png";
import IosIcon from "../../../images/ios.png";
import AndroidIcon from "@mui/icons-material/Android";

import {
  appStoreLink,
  chromeExtensionLink,
  edgeExtensionLink,
  mozillaExtensionLink,
  playStoreLink,
} from "../../System/System";

export interface NotSupportedOSSectionProps {
  supportedBrowser: boolean | undefined;
  browser: any;
  os: undefined | String;
}

const NotSupportedOSSection: React.FC<NotSupportedOSSectionProps> = ({
  supportedBrowser,
  browser,
  os,
}) => {
  const { t } = useTranslation();
  return (
    <div className="notSupported">
      <div>
        <DesktopAccessDisabledIcon className="OSNotSupportedIcon" />
      </div>
      <div>
        <Typography>
          {t("Installation.currentOS")}
          <Typography style={{ display: "inline", padding: 5 }} variant="h6">
            {os}
          </Typography>
          {t("Installation.notSupported")}
        </Typography>
      </div>
      <div className="supported">
        <Typography>{t("Installation.supportedOSes")}</Typography>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            className="supportedItem"
            style={{ opacity: !supportedBrowser ? 0.5 : 1 }}
          >
            <Link
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noopener noreferrer"
              href={
                !browser
                  ? undefined
                  : browser === "Chrome"
                    ? chromeExtensionLink
                    : browser === "Firefox"
                      ? mozillaExtensionLink
                      : browser === "Edge"
                        ? edgeExtensionLink
                        : undefined
              }
            >
              <Card className="supportedCard">
                <CardActionArea>
                  <CardContent sx={{ p: 2 }}>
                    <img
                      alt="apple"
                      src={AppleIcon}
                      className="supportedIcon appleIcon"
                    />
                    <div>{t("Installation.macos")}</div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Link>
            {!supportedBrowser && (
              <Typography variant="body2" style={{ width: "100%" }}>
                {t("Installation.BrowserNotSupportedSubtext")}
              </Typography>
            )}
          </div>
          <div
            style={{ opacity: !supportedBrowser ? 0.5 : 1 }}
            className="supportedItem"
          >
            <Link
              style={{ textDecoration: "none" }}
              href={
                !browser
                  ? undefined
                  : browser === "Chrome"
                    ? chromeExtensionLink
                    : browser === "Firefox"
                      ? mozillaExtensionLink
                      : browser === "Edge"
                        ? edgeExtensionLink
                        : undefined
              }
            >
              <Card className="supportedCard">
                <CardActionArea>
                  <CardContent sx={{ p: 2 }}>
                    <img
                      alt="windows"
                      src={WindowsIcon}
                      className="supportedIcon windowsIcon"
                    />
                    <div>{t("Installation.windows")}</div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Link>
            {!supportedBrowser && (
              <Typography variant="body2" style={{ width: "100%" }}>
                {t("Installation.BrowserNotSupportedSubtext")}
              </Typography>
            )}
          </div>
          <div className="supportedItem">
            <Card
              className="supportedCard"
              onClick={async () => {
                await setupDeferredDeepLinkForLatestInvitation(); //can only be done in response to a user interation
                console.debug("testing:redirectedToIOSAppStore");
                window.open(appStoreLink, "_self");
              }}
            >
              <CardActionArea>
                <CardContent sx={{ p: 2 }}>
                  <img
                    alt="ios"
                    src={IosIcon}
                    className="supportedIcon iosIcon"
                  />
                  <div>{t("Installation.ios")}</div>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
          <div className="supportedItem">
            <Card
              className="supportedCard"
              onClick={async () => {
                await setupDeferredDeepLinkForLatestInvitation(); //can only be done in response to a user interation
                window.open(playStoreLink, "_self");
              }}
            >
              <CardActionArea>
                <CardContent sx={{ p: 2 }}>
                  <AndroidIcon className="supportedIcon androidIcon" />
                  <div>{t("Installation.android")}</div>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotSupportedOSSection;
