// hooks/useRedirectToApplication.ts
import { useCallback } from "react";
import { Transaction } from "../../API/XFA_DEVICE_API";
import { wipeTransactionID } from "../../Token/utils/transactionHelpers";
import { useSearchParams } from "react-router-dom";

interface UseRedirectToApplicationProps {
  setError: (error: string | undefined) => void;
}

export const useRedirectToApplication = ({
  setError,
}: UseRedirectToApplicationProps) => {
  const [searchParams] = useSearchParams();

  const handleRedirectToApplication = useCallback(
    (transaction: Transaction) => {
      // Check if redirectUrl is present
      if (!transaction.redirectUrl) {
        setError("No redirect URL present");
        return;
      }

      // Check if token is present
      if (!transaction.access_token) {
        setError("No token present");
        return;
      }

      // Add token to redirectUrl as base64 encoded JSON
      const urlWithToken = new URL(transaction.redirectUrl);
      urlWithToken.searchParams.set("token", transaction.access_token);

      // Prevent redirect for tests and stories
      if (
        searchParams.has("redirect") &&
        searchParams.get("redirect") === "false"
      ) {
        return;
      }

      // Clean out transactionId before redirect
      wipeTransactionID();

      // Actual redirect
      window.location.assign(urlWithToken.toString());
    },
    [searchParams, setError],
  );

  return { handleRedirectToApplication };
};
