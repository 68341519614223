import { NavigateFunction } from "react-router-dom";

export const goToTokenTransaction = (navigate: NavigateFunction) => {
  navigate("/token");
};

export const goToVerificationTransaction = (navigate: NavigateFunction) => {
  navigate("/verify");
};

export const goToInvitationTransaction = (
  navigate: NavigateFunction,
  openedViaProtocolHandler: boolean = false,
) => {
  navigate(`/invitation?openedViaProtocolHandler=${openedViaProtocolHandler}`);
};

export const goToMfa = (navigate: NavigateFunction, transactionId: string) => {
  navigate(`/mfa?transactionId=${transactionId}`);
};

export const goToInstallationFromTokenTransaction = (
  navigate: NavigateFunction,
  transactionId: string,
  verification: boolean = false,
) => {
  navigate(
    `/installation?transactionId=${transactionId}&verification=${verification}`,
  );
};
