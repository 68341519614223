import { getInvitations } from "../Invitations/Invitations";

export const saveDeeplinkToClipboard = async (token: string) => {
  await navigator.clipboard.writeText(`xfa:${token}`);
};

export const setupDeferredDeepLinkForLatestInvitation = async () => {
  const invitations = getInvitations();
  if (invitations && invitations.length) {
    const latestInvitation = invitations.sort(
      (a, b) => b.received - a.received,
    )[0];
    await saveDeeplinkToClipboard(latestInvitation.token);
  }
};
