import React from "react";
import { Button, Card, Typography } from "@mui/material";
import BlockingIcon from "../../../../images/warning-triangle-solid.png";
import WarningIcon from "../../../../images/warning-circle.png";
import ArrowIcon from "../../../../images/arrow-right.png";
import "./DecisionListItem.css";
import { useTranslation } from "react-i18next";

export interface DecisionListItemProps {
  message: string;
  blocking: boolean;
  buttonText: string;
  onActionClick: () => void;
  timeTillBlockedInHours?: number;
}

const DecisionListItem: React.FC<DecisionListItemProps> = ({
  message,
  blocking,
  buttonText,
  onActionClick,
  timeTillBlockedInHours,
}) => {
  const { t } = useTranslation();
  const days: number | undefined =
    timeTillBlockedInHours !== undefined
      ? Math.round(timeTillBlockedInHours / 24)
      : undefined;

  return (
    <div className="encryption-status-container">
      <Card className="encryption-status-alert" onClick={onActionClick}>
        <div className="horizontal">
          <img
            src={blocking ? BlockingIcon : WarningIcon}
            alt="Warning"
            className="encryption-icon"
          />
          <div className="encryption-text-container">
            <Typography className="encryption-text">{message}</Typography>
            <Typography
              className={
                blocking
                  ? "encryption-text-small-blocking"
                  : "encryption-text-small-warning"
              }
            >
              {blocking
                ? t("decisions.blocked")
                : timeTillBlockedInHours !== undefined
                  ? timeTillBlockedInHours <= 24
                    ? t("decisions.timeTillBlocked") +
                      t("decisions.hours", { count: timeTillBlockedInHours })
                    : t("decisions.timeTillBlocked") +
                      t("decisions.days", { count: days })
                  : ""}
            </Typography>
          </div>
        </div>
        <Button
          className={
            blocking
              ? "encryption-button-blocking"
              : "encryption-button-warning"
          }
          variant="contained"
          onClick={onActionClick}
        >
          <Typography className="encryption-button-text">
            {buttonText}
          </Typography>
          <img src={ArrowIcon} alt="arrow-right" className="arrow-icon" />
        </Button>
      </Card>
    </div>
  );
};

export default DecisionListItem;
