// useTransactionPolling.ts
import { useEffect } from "react";
import { Transaction } from "../../API/XFA_DEVICE_API";

export const useTransactionPolling = (
  transaction: Transaction | undefined,
  error: string | undefined,
  waitForConfirmation: boolean,
  pending: boolean,
  updateTransaction: (transactionId: string) => void,
) => {
  useEffect(() => {
    if (
      error !== undefined ||
      error === "" ||
      (!waitForConfirmation && !pending) ||
      !transaction?.transactionId
    ) {
      return;
    }

    const interval = setInterval(() => {
      updateTransaction(transaction.transactionId);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [error, transaction, waitForConfirmation, updateTransaction, pending]);
};
