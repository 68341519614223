import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "./CheckingSection.css";
import "../../General/general.css";
import { CircularProgress } from "@mui/material";
import * as Sentry from "@sentry/react";
import { Transaction } from "../../API/XFA_DEVICE_API";
import { useTranslation } from "react-i18next";

export interface CheckingSectionProps {
  skip: () => void;
  transactionIsSkippable: boolean | undefined;
  deviceCheck: boolean;
  transaction?: Transaction;
  recheck: () => void;
  shouldRetry?: boolean;
  goToInstallation?: () => void;
}

const CheckingSection: React.FC<CheckingSectionProps> = ({
  skip,
  transactionIsSkippable,
  deviceCheck,
  transaction,
  recheck,
  shouldRetry,
  goToInstallation,
}) => {
  const { t } = useTranslation();
  const [retryOpen, setRetryOpen] = React.useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setRetryOpen(true);
      // Log to Sentry after 5 seconds
      Sentry.captureMessage(
        transaction
          ? transaction!.toString()
          : "Checking takes longer than 5 seconds - no transaction found",
      );
    }, 5000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="general">
      <Typography className="header">
        {deviceCheck ? t("Token.loading") : t("Token.checking")}
      </Typography>
      <div className="checking">
        <div className="loading">
          <div>
            <CircularProgress className="indicator" />
          </div>
        </div>

        {transactionIsSkippable === true && (
          <div className="button-container">
            <Button className="skip-button" onClick={skip} size="small">
              <Typography className="skip-text">{t("Token.skip")}</Typography>
            </Button>
          </div>
        )}

        {retryOpen === true && shouldRetry && (
          <div
            className="button-container"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h2 style={{ marginTop: "40px" }}>{t("Token.retryTitle")}</h2>
            <span style={{ marginBottom: "20px" }}>
              {t(`Token.retryDescription`)}
            </span>
            <Button
              variant="contained"
              className="black-button"
              onClick={recheck}
            >
              {t(`Token.retryButton`)}
            </Button>
            {goToInstallation && (
              <>
                <span style={{ marginTop: "24px", marginBottom: "16px" }}>
                  {t("Token.appNotInstalledMessage")}
                </span>
                <Button
                  variant="text"
                  className="grey-button"
                  onClick={goToInstallation}
                >
                  {t(`Installation.install`)}
                </Button>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckingSection;
