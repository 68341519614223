import React, { useEffect } from "react";
import {
  // eslint-disable-next-line camelcase
  _token__transactionId__post_request,
  DeviceApi,
  Transaction,
} from "../../API/XFA_DEVICE_API";
// eslint-disable-next-line camelcase
import action = _token__transactionId__post_request.action;
import { useTranslation } from "react-i18next";

// Poll for transaction status - MfaCredIdFoundOnDevice
// If MfaCredIdFoundOnDevice === true -> Native app will perform mfa
// Else -> Send MFA to start the wait for confirmation.
export const useSendMfa = ({
  transaction,
  isWaitingForConfirmation,
  setIsWaitingForConfirmation,
  setError,
  deviceClient,
  isFirstMfaDevice,
}: {
  transaction: Transaction | undefined;
  isWaitingForConfirmation: boolean;
  setIsWaitingForConfirmation: (value: boolean) => void;
  setError: (error: any) => void;
  deviceClient: DeviceApi;
  isFirstMfaDevice: boolean;
}) => {
  const [hasSendMfa, setHasSendMfa] = React.useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (hasSendMfa) {
      return;
    }

    if (
      !transaction ||
      (!isWaitingForConfirmation &&
        !(transaction.decisions?.mfa?.autoApprove === true && isFirstMfaDevice))
    ) {
      return;
    }
    setHasSendMfa(true);

    deviceClient.default
      .postToken1(transaction?.transactionId!, { action: action.SEND_MFA })
      .then(() => {
        if (
          transaction.status === "SKIPPED" ||
          transaction.status === "UNSUPPORTED" ||
          transaction.status === "GRANTED"
        ) {
          setIsWaitingForConfirmation(true);
        } else if (
          transaction.decisions?.mfa?.autoApprove === true &&
          isFirstMfaDevice
        ) {
          setIsWaitingForConfirmation(false);
        } else {
          setIsWaitingForConfirmation(
            transaction?.decisions?.mfa?.AlwaysVerify ||
              transaction?.decisions?.mfa?.MfaCredIdFoundOnDevice === false,
          );
        }
      })
      .catch((error) => {
        setError(t("mfa.errors.sendingFailed"));
        setHasSendMfa(false);
      });
  }, [
    hasSendMfa,
    transaction,
    isWaitingForConfirmation,
    deviceClient,
    setIsWaitingForConfirmation,
    setError,
    isFirstMfaDevice,
    t,
  ]);
};
