import { Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export const MfaButtons: React.FC<{
  onYesClick: () => void;
  onNoClick: () => void;
}> = ({ onYesClick, onNoClick }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="mfa-content">{t("mfa.question")}</div>
      <div className="mfa-buttons">
        <Button
          variant="contained"
          className="grey-button"
          size="large"
          onClick={onNoClick}
        >
          {t("mfa.noButton")}
        </Button>
        <Button
          variant="contained"
          className="black-button"
          size="large"
          onClick={onYesClick}
        >
          {t("mfa.yesButton")}
        </Button>
      </div>
    </div>
  );
};
