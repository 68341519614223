import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import AppIcon from "../../../images/General_XFA_app_icon.png";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import RecheckIcon from "../../../images/recheck-icon.svg";
import WarningIcon from "../../../images/warning-circle-red-icon.svg";
import CheckedIcon from "../../../images/performed_check.png";
import { isBraveOrSafari, OS } from "../../System/System";
import "../../General/general.css";
import { useClientApiContext } from "../../Extension/ClientApiContext";
import { Transaction } from "../../API/XFA_DEVICE_API";
import { Invitation } from "../../Invitations/Invitation";
import { getInvitations } from "../../Invitations/Invitations";
import { goToInvitationTransaction } from "../../Navigation/Navigation";
import { useNavigate } from "react-router-dom";

interface InstallInstructionsSectionProps {
  invited: boolean | undefined;
  browser: any;
  isPrivate: boolean | undefined;
  os: OS;
  transaction: Transaction;
}

type StepWithoutImage = {
  type: "text";
  label: string;
};

type StepWithImage = {
  type: "image";
  label: string;
  image: string;
};

type StepType = StepWithoutImage | StepWithImage;
export type BrowserType = "Chrome" | "Firefox" | "Edge" | "Safari" | "Brave";

const InstallInstructionsSection: React.FC<InstallInstructionsSectionProps> = ({
  invited,
  browser,
  isPrivate,
  os,
  transaction,
}) => {
  const { t } = useTranslation();
  const { clientApi, checking, isNativeClientAvailable, recheck } =
    useClientApiContext();
  const [isPrivateDialogOpen, setIsPrivateDialogOpen] = useState(false);
  const [didTapDownload, setDidTapDownload] = useState(false);
  const [didRecheck, setDidRecheck] = useState(
    new URLSearchParams(window.location.search).get("recheck") === "true",
  );
  const [recheckTimerStarted, setRecheckTimerStarted] = useState(false);
  const recheckElapsed = Number(
    new URLSearchParams(window.location.search).get("recheckElapsed") ?? 0,
  );
  let url: string | undefined = undefined;
  const timeoutTime = 1;
  const navigate = useNavigate();

  if (os === "macOS") {
    url =
      "https://distribution.xfa.tech/xfa-native-desktop-application/XFA.pkg";
  } else if (os === "Windows") {
    url =
      "https://distribution.xfa.tech/xfa-native-desktop-application/XFA.msi";
  }
  const [invitations, setInvitations] = React.useState<
    Invitation[] | undefined
  >(undefined);

  React.useEffect(() => {
    let invitations: Invitation[] = [];

    // get invitations from localstorage, add them
    invitations = invitations.concat(getInvitations());
    setInvitations(invitations);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (didTapDownload && !isBraveOrSafari(navigator)) {
      const interval = setInterval(() => {
        recheck(false, transaction?.transactionId ?? "");
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line
  }, [didTapDownload]);

  useEffect(() => {
    if (didRecheck) {
      setRecheckTimerStarted(true);
      const interval = setInterval(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const currentElapsed = Number(searchParams.get("recheckElapsed")) || 0;
        const newElapsed = currentElapsed + 1;
        searchParams.set("recheckElapsed", newElapsed.toString());
        const newUrl = new URL(window.location.href);
        newUrl.search = searchParams.toString();
        window.history.replaceState(null, "", newUrl.toString());

        if (newElapsed >= timeoutTime) {
          clearInterval(interval);
          setDidRecheck(false);
        } else {
          recheck(false, "", true);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [didRecheck, recheck]);

  const browserDetails: Record<
    BrowserType,
    { storeLink: string; steps: StepType[]; privateSteps?: StepType[] }
  > = ["Chrome", "Firefox", "Edge", "Safari", "Brave"].reduce(
    (acc, browser) => {
      acc[browser as BrowserType] = {
        storeLink: url!,
        steps: [
          { type: "text", label: t("Installation.nativeClient.step1") },
          {
            type: "text",
            label:
              os === "macOS"
                ? t("Installation.nativeClient.step2")
                : t("Installation.nativeClient.step2Windows"),
          },
          { type: "text", label: t("Installation.nativeClient.step3") },
        ],
      };
      return acc;
    },
    {} as Record<
      BrowserType,
      { storeLink: string; steps: StepType[]; privateSteps?: StepType[] }
    >,
  );

  const { steps, storeLink, privateSteps } =
    browserDetails[browser as BrowserType];

  const performRecheck = () => {
    setDidRecheck(true);
    const newerUrl = new URL(window.location.href);
    newerUrl.searchParams.set("recheck", "true");
    newerUrl.searchParams.set("recheckElapsed", "0");
    window.history.replaceState(null, "", newerUrl.toString());

    if (
      isBraveOrSafari(navigator) &&
      window.location.href.includes("invited")
    ) {
      if (invitations && invitations.length > 0) {
        clientApi.sendInvitations(invitations, true);
        goToInvitationTransaction(navigate, true);
      }
      return;
    }

    recheck(true, transaction?.transactionId ?? "");

    if (isBraveOrSafari(navigator)) {
      return;
    }

    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set("recheck", "true");
    newUrl.searchParams.set("recheckElapsed", "0");
    window.history.replaceState(null, "", newUrl.toString());
  };

  const renderRecheckButton = (index: number) => {
    if (index !== 2 || checking) return null;

    const icon =
      recheckTimerStarted && recheckElapsed >= timeoutTime
        ? isNativeClientAvailable
          ? CheckedIcon
          : WarningIcon
        : RecheckIcon;

    return (
      <Button
        disabled={
          didRecheck && recheckTimerStarted && recheckElapsed < timeoutTime
        }
        className="recheck-button"
        startIcon={
          <img
            src={icon}
            alt="Recheck"
            className={
              didRecheck && recheckTimerStarted && recheckElapsed < timeoutTime
                ? "rotating-icon"
                : ""
            }
          />
        }
        onClick={performRecheck}
      >
        <Typography className="install-text" style={{ flex: 1 }}>
          {didRecheck && recheckTimerStarted && recheckElapsed < timeoutTime
            ? t("Installation.nativeClient.rechecking")
            : !isNativeClientAvailable && recheckElapsed >= timeoutTime
              ? t("Installation.nativeClient.notInstalled")
              : t("Installation.nativeClient.recheck")}
        </Typography>
      </Button>
    );
  };

  const renderStepContent = (step: StepType, index: number) => {
    if (step.type === "image") {
      return <img alt={step.label} style={{ width: 300 }} src={step.image} />;
    }
    if (index === 0) {
      return (
        <Button
          variant="contained"
          className="black-button"
          onClick={() => {
            setDidTapDownload(true);
            window.open(storeLink, "_blank");
          }}
        >
          {t(`Installation.nativeClient.step1Button`)}
        </Button>
      );
    }
    return renderRecheckButton(index);
  };

  return (
    <>
      <div>
        <Typography className="header">
          {!invited
            ? t("Installation.nativeClient.title")
            : t("Installation.titleInvited")}
        </Typography>
        <Typography className="body1">
          {t("Installation.nativeClient.description")}
        </Typography>
      </div>

      {isPrivate && (browser as BrowserType) === "Edge" && (
        <>
          <h3 className="header">{t("Installation.isPrivate")}</h3>
          <div className="button-container" style={{ width: "100%" }}>
            <Button
              startIcon={
                <img className="smallIcon" src={AppIcon} alt="Ongoing" />
              }
              className="icon-button"
              onClick={() => setIsPrivateDialogOpen(true)}
              endIcon={<ChevronRightIcon />}
              style={{ width: "100%" }}
            >
              <Typography className="install-text" style={{ flex: 1 }}>
                {t("Installation.isPrivateButton")}
              </Typography>
            </Button>
          </div>
        </>
      )}

      <Stepper className="instructions" orientation="vertical">
        {steps.map((step, index) => (
          <Step
            key={index}
            active
            sx={{
              ".MuiStepLabel-label": {
                color: "var(--color-gray-600) !important",
                fontSize: 14,
                fontWeight: 500,
                lineHeight: "19px",
                marginLeft: "32px",
                letterSpacing: "0em",
              },
            }}
          >
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              <div className="stepContent">
                {renderStepContent(step, index)}
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      <div className="button-container">
        <Button
          className="double-text-button"
          onClick={performRecheck}
          endIcon={<ChevronRightIcon />}
        >
          <div className="button-text">
            <Typography className="title">
              {t("Installation.alreadyInstalledTitle")}
            </Typography>
            <Typography className="description">
              {t("Installation.alreadyInstalledDescription")}
            </Typography>
          </div>
        </Button>
      </div>

      <Dialog
        open={isPrivateDialogOpen}
        onClose={() => setIsPrivateDialogOpen(false)}
        maxWidth="md"
      >
        <DialogTitle>
          {t("Installation.installationInstructionsPrivate")}
          <IconButton
            aria-label="close"
            onClick={() => setIsPrivateDialogOpen(false)}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stepper className="instructions" orientation="vertical">
            {privateSteps?.map((step, index) => (
              <Step key={index} active>
                <StepLabel>{step.label}</StepLabel>
                {step.type === "image" && (
                  <StepContent>
                    <div className="stepContent">
                      <img
                        alt={step.label}
                        style={{ width: 300 }}
                        src={step.image}
                      />
                    </div>
                  </StepContent>
                )}
              </Step>
            ))}
          </Stepper>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InstallInstructionsSection;
