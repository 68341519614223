import { Transaction } from "../../API/XFA_DEVICE_API";
import { setupDeferredDeepLinkForLatestInvitation } from "../../Deeplinks/Deeplinks";
import { goToInstallationFromTokenTransaction } from "../../Navigation/Navigation";
import {
  appStoreLink,
  OS,
  playStoreLink,
  supportedBrowsers,
  supportedIOSes,
  supportedMobileOSes,
} from "../../System/System";

export const redirectWithProtocolOnIos = (transaction: Transaction) => {
  console.debug("testing:redirectWithProtocolOnIos");
  window.location.assign(
    "xfaapp://token-mobile?transactionId=" + transaction.transactionId,
  );
};

export const goToInstallationStep = async (
  transaction: Transaction,
  os: OS,
  browser: any,
  navigate: any,
  verification: boolean = false,
) => {
  if (os && supportedMobileOSes.includes(os)) {
    await setupDeferredDeepLinkForLatestInvitation(); //can only be done in response to a user interation
    if (supportedIOSes.includes(os as OS)) {
      console.debug("testing:redirectedToIOSAppStore");
      window.open(appStoreLink, "_self");
    } else if (os === "Android") {
      window.open(playStoreLink, "_self");
    }
  } else if (supportedBrowsers.includes(browser ?? "")) {
    goToInstallationFromTokenTransaction(
      navigate,
      transaction.transactionId,
      verification,
    );
  }
};
