import { Typography, CircularProgress } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export const MfaHeader: React.FC<{
  waitForConfirmation: boolean;
  pending: boolean;
}> = ({ waitForConfirmation, pending }) => {
  const { t } = useTranslation();
  return (
    <div className="mfa-header-content">
      <Typography className="mfa-header">
        {waitForConfirmation
          ? t("mfa.title")
          : t(pending ? "mfa.generalTitle" : "mfa.trustTitle")}
      </Typography>
      {pending && <CircularProgress className="indicator" />}
    </div>
  );
};
