import React, { createContext, useContext } from "react";
import useClientApi from "./Client";

const ClientApiContext = createContext<ReturnType<typeof useClientApi> | null>(
  null,
);

export const ClientApiProvider = ({ children, navigator, browser }: any) => {
  const clientApiValue = useClientApi(navigator, browser);
  return (
    <ClientApiContext.Provider value={clientApiValue}>
      {children}
    </ClientApiContext.Provider>
  );
};

export const useClientApiContext = () => {
  const context = useContext(ClientApiContext);
  if (!context) {
    throw new Error(
      "useClientApiContext should be used within a ClientApiProvider",
    );
  }
  return context;
};
