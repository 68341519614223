import React from "react";
import {
  OS,
  Browser,
  playStoreLink,
  supportedIOSes,
  appStoreLink,
  isBraveOrSafari,
} from "../../System/System";
import { ConnectSection } from "./ConnectSection";
import ExtensionInstalledSection from "./ExtensionInstalledSection";
import InstallInstructionsSection from "./InstallInstructionSection";
import { useClientApiContext } from "../../Extension/ClientApiContext";
import { setupDeferredDeepLinkForLatestInvitation } from "../../Deeplinks/Deeplinks";
import { useSupportedStateLogic } from "../hooks/useSupportedStateLogic";
import { useNavigate } from "react-router-dom";
import { goToInvitationTransaction } from "../../Navigation/Navigation";

interface SupportedStateProps {
  navigator: any;
  os: OS;
  browser: Browser;
  transaction: any;
}

export const SupportedState: React.FC<SupportedStateProps> = ({
  navigator,
  os,
  browser,
  transaction,
}) => {
  const {
    isExtensionInstalled,
    isNativeClientAvailable,
    isProtocolHandlerAvailable,
    recheck,
  } = useClientApiContext();
  const braveOrSafari = isBraveOrSafari(navigator);

  const { isPrivate, invited, ongoingTransaction } = useSupportedStateLogic();
  const navigate = useNavigate();

  if (["Windows", "macOS"].includes(os)) {
    if (invited && isNativeClientAvailable) {
      goToInvitationTransaction(navigate);
    }
    // Desktop flow
    return (
      <>
        {isNativeClientAvailable ||
        isExtensionInstalled ||
        (isProtocolHandlerAvailable && braveOrSafari && !transaction) ? (
          <ExtensionInstalledSection
            browser={browser}
            ongoingTransaction={ongoingTransaction}
          />
        ) : (
          <InstallInstructionsSection
            transaction={transaction}
            invited={invited}
            browser={browser ?? ""}
            isPrivate={isPrivate}
            os={os}
          />
        )}
      </>
    );
  }

  if (os === "Android") {
    // Mobile flow for Android
    return (
      <ConnectSection
        goToInstallation={async () => {
          await setupDeferredDeepLinkForLatestInvitation(); //can only be done in response to a user interaction
          console.debug("testing:redirectedToIOSAppStore");
          window.open(playStoreLink, "_self");
        }}
        organization={transaction?.organization?.name}
        organizationIconUrl={transaction?.organization?.logoUrl}
        mobile={true}
        invited={invited ?? false}
        isSkippable={undefined}
        recheck={() => {
          recheck(true, transaction?.transactionId ?? "");
        }}
      />
    );
  }

  if (supportedIOSes.includes(os)) {
    // Mobile flow for iOS
    return (
      <ConnectSection
        goToInstallation={async () => {
          await setupDeferredDeepLinkForLatestInvitation(); //can only be done in response to a user interaction
          console.debug("testing:redirectedToIOSAppStore");
          window.open(appStoreLink, "_self");
        }}
        organization={transaction?.organization?.name}
        organizationIconUrl={transaction?.organization?.logoUrl}
        mobile={true}
        invited={invited ?? false}
        isSkippable={undefined}
        recheck={() => {
          recheck(true, transaction?.transactionId ?? "");
        }}
      />
    );
  }

  return null;
};
