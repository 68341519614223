import React, { useEffect } from "react";
import { SupportedState } from "./SupportedState";
import { UnsupportedState } from "./UnsupportedState";
import { isBraveOrSafari, OS } from "../../System/System";
import { isSupported } from "../utils/utils";
import { useClientApiContext } from "../../Extension/ClientApiContext";
import useTransaction from "../../Token/hooks/useTransaction";
import {
  goToTokenTransaction,
  goToVerificationTransaction,
} from "../../Navigation/Navigation";
import { useNavigate } from "react-router-dom";
import CheckingSection from "../../Token/sections/CheckingSection";

export interface ContentProps {
  navigator: any;
  os: OS | undefined;
  browser: any;
}

export const Content: React.FC<ContentProps> = ({ navigator, os, browser }) => {
  const { checking, silent, isProtocolHandlerAvailable, recheck } =
    useClientApiContext();
  const braveOrSafari = isBraveOrSafari(navigator);
  const { transaction, updateTransaction } = useTransaction(navigator);
  const navigate = useNavigate();

  useEffect(() => {
    if (isProtocolHandlerAvailable && transaction && braveOrSafari) {
      const interval = setInterval(() => {
        updateTransaction(transaction.transactionId);
        if (transaction.protocolHandlerReceived && transaction.verification) {
          goToVerificationTransaction(navigate);
        } else if (transaction.protocolHandlerReceived) {
          goToTokenTransaction(navigate);
        }
      }, 2000); // Poll
      return () => clearInterval(interval);
    }
  }, [
    braveOrSafari,
    isProtocolHandlerAvailable,
    transaction,
    navigate,
    updateTransaction,
  ]);

  if (
    !os ||
    (checking && !silent) ||
    (isProtocolHandlerAvailable && transaction && braveOrSafari)
  ) {
    return (
      <CheckingSection
        transaction={transaction}
        skip={() => {}}
        transactionIsSkippable={false}
        deviceCheck={true}
        recheck={() => {
          recheck(true, transaction?.transactionId);
        }}
        shouldRetry={braveOrSafari || isProtocolHandlerAvailable}
        goToInstallation={() => {
          window.location.reload();
        }}
      />
    );
  }
  if (!isSupported(os, browser)) {
    return <UnsupportedState os={os} browser={browser} />;
  }
  return (
    <SupportedState
      navigator={navigator}
      os={os}
      browser={browser}
      transaction={transaction}
    />
  );
};
