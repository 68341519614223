// useTransactionStatusHandler.ts
// TODO: Remove when extension is removed
import { useEffect } from "react";
import { Transaction } from "../../API/XFA_DEVICE_API";

interface UseTransactionStatusHandlerProps {
  error: string | undefined;
  enroll: boolean;
  waitForConfirmation: boolean;
  transaction: Transaction | undefined;
  register: () => void;
  handleRedirectToApplication: (transaction: Transaction) => void;
  setError: (error: string | undefined) => void;
}

export const useTransactionStatusHandler = ({
  error,
  enroll,
  waitForConfirmation,
  transaction,
  register,
  handleRedirectToApplication,
  setError,
}: UseTransactionStatusHandlerProps) => {
  useEffect(() => {
    if (error !== undefined || error === "") return;

    if (
      transaction?.decisions?.mfa?.AlwaysVerify === true &&
      transaction.decisions.mfa.Confirmed === true &&
      transaction?.decisions?.mfa?.status === "OK"
    ) {
      handleRedirectToApplication(transaction);
    } else if (
      enroll &&
      waitForConfirmation &&
      transaction?.decisions?.mfa?.status === "OK"
    ) {
      register();
    } else if (
      !enroll &&
      waitForConfirmation &&
      transaction?.decisions?.mfa?.status === "OK"
    ) {
      handleRedirectToApplication(transaction);
    } else if (
      waitForConfirmation &&
      transaction?.decisions?.mfa?.status === "BLOCKING" &&
      transaction?.decisions?.mfa?.error !== ""
    ) {
      setError(transaction?.decisions?.mfa?.error);
    }
  }, [
    error,
    transaction,
    enroll,
    waitForConfirmation,
    register,
    handleRedirectToApplication,
    setError,
  ]);
};
