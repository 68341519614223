import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import XFAIcon from "../../images/XFA_woordmerk_donkergrijs.svg";

import {
  appStoreLink,
  isBraveOrSafari,
  OS,
  playStoreLink,
  supportedIOSes,
  supportedMobileOSes,
  TokenStatus,
} from "../System/System";
import { setupDeferredDeepLinkForLatestInvitation } from "../Deeplinks/Deeplinks";
import { goToInstallationFromTokenTransaction } from "../Navigation/Navigation";

import {
  IntercomOverlay,
  updateIntercomProps,
} from "../intercom/intercomProvider";
import SetFavicon from "../General/SetFavicon";
import TopBar from "../General/TopBar";

// utils
import {
  goToInstallationStep,
  redirectWithProtocolOnIos,
} from "./utils/redirectHelpers";
import { useCheckAffiliationConditions } from "./utils/affiliationConditions";
import { transactionCompletion } from "./utils/transactionCompletion";
import { handleUnsupportedTransaction } from "./utils/handleUnsupportedTransaction";
import { handleCompletedTransaction } from "./utils/handleCompletedTransaction";
//hooks
import useTransaction from "./hooks/useTransaction";
import { useDeviceSupport } from "./hooks/useDeviceSupport";
import { useSkipTransaction } from "./hooks/useSkipTransaction";
import { useCompleteTransaction } from "./hooks/useCompleteTransaction";
import { useEndTransaction } from "./hooks/useEndTransaction";
import { useMergeShadows } from "./hooks/useMergeShadows";
// sections
import CheckingSection from "./sections/CheckingSection";
import { ClientMissingSection } from "./sections/ClientMissingSection";
import { RedirectingSection, VerifiedSection } from "./sections/SuccessSection";
import { ErrorSection } from "./sections/ErrorSection";
import { NokSection } from "./sections/NokSection";
import { ConnectSection } from "../Installation/sections/ConnectSection";
// css
import "./Token.css";
import "../General/general.css";
import { useClientApiContext } from "../Extension/ClientApiContext";

export interface TokenProps {
  navigator: any;
  browser: any;
}

export interface TokenRequestCustomFlow {
  redirectUrl: string;
  applicationId?: string;
  applicationDomain?: string;
  email?: string;
  requested: number;
}

const Token: React.FC<TokenProps> = (props: TokenProps) => {
  const { t } = useTranslation();
  const {
    isClientAvailable,
    recheck,
    isProtocolHandlerAvailable,
    isExtensionInstalled,
    isNativeClientAvailable,
  } = useClientApiContext();
  const { os, browser, supported } = useDeviceSupport(
    props.navigator,
    isClientAvailable,
  );

  const [mfa, setMfa] = React.useState<boolean>(false);
  const [showConnectSection, setShowConnectSection] =
    React.useState<boolean>(false);
  const [showClientMissingSection, setShowClientMissingSection] =
    React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");
  const [tokenStatus, setTokenStatus] = React.useState<TokenStatus>("Checking");
  const [appNotInstalled, setAppNotInstalled] = React.useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [polling, setPolling] = React.useState<boolean>(false);
  const [didTapVerify, setDidTapVerify] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const [
    attemptedAutoCompletingTransaction,
    setAttemptedAutoCompletingTransaction,
  ] = React.useState(false);

  const [devicePreviouslyAffiliated, setDevicePreviouslyAffiliated] =
    React.useState<boolean | undefined>(undefined);
  const {
    transaction,
    updateTransaction,
    transactionError,
    setTransaction,
    transactionIsSkippable,
  } = useTransaction(props.navigator);
  const [completingTransaction, setCompletingTransaction] =
    React.useState<boolean>(false);
  const [isWaitingForNativeApp, setIsWaitingForNativeApp] =
    React.useState<boolean>(false);

  const [transactionAllowsUnsupported, setTransactionAllowsUnsupported] =
    React.useState<boolean | undefined>(undefined);

  const checkAffiliationConditions = useCheckAffiliationConditions();
  const braveOrSafari = isBraveOrSafari(navigator);

  //check if transaction allows unsupported devices
  React.useEffect(() => {
    if (!transaction) {
      return;
    }

    if (transaction.status === "PENDING") {
      if (transaction.decisions?.unsupported?.allowed === true) {
        setTransactionAllowsUnsupported(true);
      }
    }
  }, [transaction]);

  //manage error to show
  React.useEffect(() => {
    if (transactionError) {
      setError(transactionError);
      setTokenStatus("Error");
    }
  }, [setError, setTokenStatus, transactionError]);

  //check if app is not installed -> show not installed button on mobile
  React.useEffect(() => {
    const appNotInstalledParam = searchParams.get("appNotInstalled");
    if (appNotInstalledParam) {
      setAppNotInstalled(appNotInstalledParam === "true");
    }
  }, [searchParams]);

  const endTransaction = useEndTransaction(setTokenStatus, setMfa);
  const completeTransaction = useCompleteTransaction(
    setTokenStatus,
    props.navigator,
    setError,
    updateTransaction,
    setCompletingTransaction,
    endTransaction,
  );

  const mergeShadows = useMergeShadows();

  //redirect immediately if not supported
  useEffect(() => {
    if (!transactionAllowsUnsupported && supported === false && transaction) {
      setError(t("Token.errors.unsupported"));
      setTokenStatus("Error");
    } else if (supported === false && transaction) {
      handleUnsupportedTransaction(
        transaction,
        setTokenStatus,
        setError,
        t,
        endTransaction,
      );
    }
  }, [
    supported,
    transactionAllowsUnsupported,
    transaction,
    setTokenStatus,
    setError,
    t,
    props.navigator.userAgent,
    endTransaction,
  ]);

  const handleCompleteTransaction = React.useCallback(
    async (doRecheck: boolean, force: boolean) => {
      if (!transaction) {
        setError("Transaction is missing");
        return;
      }

      try {
        await completeTransaction(transaction, supported, doRecheck, force);
      } catch (error) {
        console.error("Error completing transaction:", error);
        setTokenStatus("Error");
        setError("An error occurred while completing the transaction");
      }
    },
    [transaction, supported, completeTransaction, setError, setTokenStatus],
  );

  // Function to refresh the transaction status
  const refreshTransactionStatus = () => {
    if (!transaction || !transaction.transactionId) {
      console.error("Geen transactie ID beschikbaar voor refresh");
      return;
    }
    console.debug("testing:completingTransaction");

    handleCompleteTransaction(true, false);
  };

  //check if device already affiliated
  useEffect(() => {
    const executeAffiliationCheck = async () => {
      try {
        await checkAffiliationConditions(
          isClientAvailable,
          devicePreviouslyAffiliated,
          transaction,
          setDevicePreviouslyAffiliated,
          setTokenStatus,
          setError,
        );
      } catch (error) {
        console.error("Error during affiliation check:", error);
        setError(t("Token.errors.affiliation"));
      }
    };
    if (
      !isProtocolHandlerAvailable ||
      isNativeClientAvailable ||
      isExtensionInstalled
    ) {
      executeAffiliationCheck();
    } else {
      handleCompleteTransaction(false, false);
    }
  }, [
    checkAffiliationConditions,
    isClientAvailable,
    isExtensionInstalled,
    isNativeClientAvailable,
    isProtocolHandlerAvailable,
    transaction,
    devicePreviouslyAffiliated,
    setDevicePreviouslyAffiliated,
    setTokenStatus,
    setError,
    t,
    handleCompleteTransaction,
  ]);

  //attempt to complete transaction or guide the user
  useEffect(() => {
    transactionCompletion(
      transaction,
      supported,
      isClientAvailable,
      devicePreviouslyAffiliated,
      setTokenStatus,
      tokenStatus,
      attemptedAutoCompletingTransaction,
      setAttemptedAutoCompletingTransaction,
      endTransaction,
      completeTransaction,
    );
  }, [
    transaction,
    supported,
    isClientAvailable,
    devicePreviouslyAffiliated,
    setTokenStatus,
    tokenStatus,
    attemptedAutoCompletingTransaction,
    setAttemptedAutoCompletingTransaction,
    endTransaction,
    completeTransaction,
    setError,
    updateTransaction,
    setCompletingTransaction,
    setMfa,
  ]);

  //forward after showing decision for a short period of time
  useEffect(() => {
    if (!transaction) return;
    handleCompletedTransaction(
      transaction,
      mergeShadows,
      setTokenStatus,
      endTransaction,
    );
  }, [
    transaction,
    mergeShadows,
    setTokenStatus,
    navigate,
    setMfa,
    endTransaction,
  ]);

  const skip = useSkipTransaction(
    transaction,
    setError,
    setTokenStatus,
    setTransaction,
    endTransaction,
  );

  React.useEffect(() => {
    setShowConnectSection(
      tokenStatus === "ClientMissing" &&
        !supportedMobileOSes.includes(os as OS),
    );

    setShowClientMissingSection(
      (tokenStatus === "ClientMissing" || tokenStatus === "Confirmation") &&
        !showConnectSection &&
        !completingTransaction &&
        supported === true &&
        !(
          braveOrSafari &&
          isProtocolHandlerAvailable &&
          (transaction?.device_id === undefined || didTapVerify)
        ),
    );

    setIsWaitingForNativeApp(
      (tokenStatus === "ClientMissing" || tokenStatus === "Confirmation") &&
        braveOrSafari &&
        isProtocolHandlerAvailable &&
        (transaction?.device_id === undefined || didTapVerify),
    );

    setPolling(
      (tokenStatus === "ClientMissing" ||
        tokenStatus === "Confirmation" ||
        tokenStatus === "Checking") &&
        (transaction?.device_id === undefined || didTapVerify) &&
        braveOrSafari,
    );
  }, [
    os,
    showConnectSection,
    supported,
    tokenStatus,
    transaction?.decisions?.mfa?.status,
    braveOrSafari,
    isProtocolHandlerAvailable,
    transaction,
    didTapVerify,
    completingTransaction,
  ]);

  React.useEffect(() => {
    updateIntercomProps(
      transaction?.user?.email,
      transaction?.organization?.name,
      transaction?.transactionId,
    );
  }, [transaction]);

  useEffect(() => {
    if (polling && transaction) {
      const interval = setInterval(() => {
        if (
          !braveOrSafari &&
          isProtocolHandlerAvailable &&
          !isNativeClientAvailable
        ) {
          recheck(false, transaction.transactionId);
        }
        updateTransaction(transaction.transactionId);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [
    polling,
    transaction,
    updateTransaction,
    tokenStatus,
    braveOrSafari,
    isProtocolHandlerAvailable,
    isNativeClientAvailable,
    recheck,
  ]);

  React.useEffect(() => {
    const blocking = Object.entries(transaction?.decisions || {}).some(
      ([key, decision]: [string, any]) =>
        decision?.status === "BLOCKING" && key !== "mfa",
    );

    if (!blocking) return;
    if (!transaction?.transactionId) return;
    if (!isClientAvailable) return;

    const interval = setInterval(() => {
      updateTransaction(transaction.transactionId);
      if (transaction.status === "GRANTED") {
        endTransaction(transaction);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [transaction, updateTransaction, endTransaction, isClientAvailable]);

  return (
    <>
      {transaction?.application?.iconUrl && transaction.application.name && (
        <SetFavicon
          title={transaction.application.name}
          url={transaction?.application?.iconUrl}
        />
      )}
      <div className="root">
        <IntercomOverlay>
          <TopBar transaction={transaction} signIn={true} />
          <div className="content-container">
            <div className="content-token">
              {!showConnectSection && (
                <div className="general">
                  <img src={XFAIcon} alt="XFAIcon" className="xfaIcon" />
                </div>
              )}
              {(tokenStatus === "Checking" || isWaitingForNativeApp) && (
                <CheckingSection
                  skip={skip}
                  transactionIsSkippable={transactionIsSkippable}
                  deviceCheck={false}
                  transaction={transaction}
                  recheck={() => {
                    recheck(true, transaction?.transactionId);
                  }}
                  shouldRetry={braveOrSafari || isProtocolHandlerAvailable}
                  goToInstallation={() => {
                    goToInstallationStep(transaction!, os!, browser, navigate);
                  }}
                />
              )}
              {showConnectSection && !isWaitingForNativeApp && (
                <ConnectSection
                  goToInstallation={() => {
                    goToInstallationStep(transaction!, os!, browser, navigate);
                  }}
                  organization={transaction?.organization?.name}
                  organizationIconUrl={transaction?.organization?.logoUrl}
                  mobile={(os && supportedMobileOSes.includes(os)) ?? false}
                  invited={false}
                  isSkippable={transactionIsSkippable}
                  skip={skip}
                  demo={transaction?.application === undefined}
                  recheck={() => {
                    recheck(true, transaction?.transactionId);
                  }}
                />
              )}
              {showClientMissingSection && (
                <ClientMissingSection
                  completeTransaction={() => {
                    if (!transaction) return;
                    setTokenStatus("Checking");
                    handleCompleteTransaction(false, true);
                    setDidTapVerify(true);
                  }}
                  goToInstallation={async () => {
                    if (!transaction) return;
                    if (supportedIOSes.includes(os as OS)) {
                      await setupDeferredDeepLinkForLatestInvitation(); //can only be done in response to a user interation
                      console.debug("testing:redirectedToIOSAppStore");
                      window.open(appStoreLink, "_self");
                    } else if (os === "Android") {
                      await setupDeferredDeepLinkForLatestInvitation(); //can only be done in response to a user interation
                      window.open(playStoreLink, "_self");
                    } else {
                      goToInstallationFromTokenTransaction(
                        navigate,
                        transaction?.transactionId,
                      );
                    }
                  }}
                  customProtocolIOS={() =>
                    redirectWithProtocolOnIos(transaction!)
                  }
                  skip={skip}
                  transaction={transaction}
                  refreshTransaction={() => {
                    updateTransaction(transaction?.transactionId!);
                  }}
                  transactionIsSkippable={transactionIsSkippable}
                  tokenStatus={tokenStatus}
                  devicePreviouslyAffiliated={devicePreviouslyAffiliated}
                  os={os}
                  organization={transaction?.organization?.name}
                  appNotInstalled={appNotInstalled}
                />
              )}
              {tokenStatus === "OK" && !mfa && (
                <>
                  {transaction?.application ? (
                    <RedirectingSection />
                  ) : (
                    transaction?.organization && (
                      <VerifiedSection
                        organizationName={transaction?.organization.name}
                      />
                    )
                  )}
                </>
              )}

              {tokenStatus === "Error" && (
                <ErrorSection
                  recheck={
                    error === t("Token.errors.unsupported")
                      ? undefined // not possible to recheck unsupported devices
                      : () => {
                          recheck(true, transaction?.transactionId);
                        }
                  }
                  error={error}
                />
              )}
              {tokenStatus === "NOK" && (
                <NokSection
                  browser={browser}
                  navigator={props.navigator}
                  transaction={transaction}
                  endTransaction={() => {
                    if (!transaction) return;
                    endTransaction(transaction);
                  }}
                  refreshTransactionStatus={refreshTransactionStatus}
                  refreshingTransaction={completingTransaction}
                />
              )}
              {tokenStatus === null && (
                <CheckingSection
                  skip={skip}
                  transactionIsSkippable={false}
                  deviceCheck={true}
                  transaction={transaction}
                  recheck={() => {
                    recheck(true, transaction?.transactionId);
                  }}
                  shouldRetry={
                    (braveOrSafari || isProtocolHandlerAvailable) &&
                    transaction?.protocolHandlerReceived === false
                  }
                />
              )}
            </div>
          </div>
        </IntercomOverlay>
      </div>
    </>
  );
};

export default Token;
