// hooks/useRegister.ts
// TODO: Remove when extension is removed
import { useCallback, useState } from "react";
import { DeviceApi, Transaction } from "../../API/XFA_DEVICE_API";
import { deviceApiConfig } from "../../Config";
import { useClientApiContext } from "../../Extension/ClientApiContext";

interface UseRegisterProps {
  transaction: Transaction | undefined;
  setError: (error: string | undefined) => void;
  setWaitForConfirmation: (state: boolean) => void;
  setPendingAction: (state: boolean) => void;
  redirectToApplication: (transaction: Transaction) => void;
}

export const useRegister = ({
  transaction,
  setError,
  setWaitForConfirmation,
  setPendingAction,
  redirectToApplication,
}: UseRegisterProps) => {
  const [registerCalled, setRegisterCalled] = useState(false);
  const { clientApi } = useClientApiContext();
  const deviceClient = new DeviceApi(deviceApiConfig);
  deviceClient.request.config.CREDENTIALS = "include";
  deviceClient.request.config.WITH_CREDENTIALS = true;

  const register = useCallback(async () => {
    if (!transaction?.transactionId) {
      setError("No transaction ID found");
      return;
    }
    if (registerCalled) return;
    setRegisterCalled(true);

    try {
      const response = await deviceClient.default.postRegisterBegin(
        transaction.transactionId,
      );

      const credential = await clientApi.createCredential(response);

      await deviceClient.default.postRegisterFinish(
        transaction.transactionId,
        response.SessionId!,
        credential,
      );

      const updatedTransaction = await deviceClient.default.getToken(
        transaction.transactionId,
      );

      if (updatedTransaction?.decisions?.mfa?.status === "OK") {
        redirectToApplication(updatedTransaction);
      } else {
        const errorMsg =
          updatedTransaction?.decisions?.mfa?.error || "Something went wrong";
        setError(errorMsg);
        setWaitForConfirmation(false);
        setPendingAction(false);
      }
    } catch (error: any) {
      console.error("Error during registration:", error);
      setError(error.message || "An unexpected error occurred");
      setWaitForConfirmation(false);
      setPendingAction(false);
    }
  }, [
    transaction,
    setError,
    setWaitForConfirmation,
    setPendingAction,
    registerCalled,
    clientApi,
    deviceClient.default,
    redirectToApplication,
  ]);

  return { register, registerCalled };
};
