import React from "react";
import "./Installation.css";

import { useNavigate } from "react-router-dom";

import useTransaction from "../Token/hooks/useTransaction";
import TopBar from "../General/TopBar";
import { useSystemDetection } from "./hooks/useSystemDetection";
import { useTransactionStatus } from "./hooks/useTransactionStatus";
import { Content } from "./sections/Content";

export interface InstallationProps {
  navigator: any;
  browser: any;
}

const Installation: React.FC<InstallationProps> = ({ navigator }) => {
  const { os, browser } = useSystemDetection(navigator);
  const { transaction } = useTransaction(navigator);
  useTransactionStatus(transaction, useNavigate());

  return (
    <div className="root">
      <TopBar transaction={transaction} signIn={true} />
      <div className="content-container">
        <div className="content-token">
          <Content navigator={navigator} os={os} browser={browser} />
        </div>
      </div>
    </div>
  );
};

export default Installation;
