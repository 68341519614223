import { useCallback } from "react";
import { Transaction } from "../../API/XFA_DEVICE_API";
import {
  checkOS,
  isBraveOrSafari,
  OS,
  supportedDesktopOSes,
  supportedMobileOSes,
  TokenStatus,
} from "../../System/System";
import { isMicrosoftAppsWebView } from "../utils/MicrosoftEAM";
import { useTranslation } from "react-i18next";
import { useClientApiContext } from "../../Extension/ClientApiContext";
import { handleRecheck } from "../utils/handleRecheck";

export const useCompleteTransaction = (
  setTokenStatus: (status: TokenStatus) => void,
  navigator: any,
  setError: (error: string) => void,
  updateTransaction: (transactionId: string) => void,
  setCompletingTransaction: (completing: boolean) => void,
  endTransaction: (transaction: Transaction) => void,
) => {
  const { t } = useTranslation();
  const userAgent = window.navigator.userAgent;
  const { clientApi, isClientAvailable, isProtocolHandlerAvailable } =
    useClientApiContext();

  const handleMobileTransaction = useCallback(
    (transaction: Transaction, os: OS) => {
      if (
        transaction.application?.type === "MicrosoftEAM" &&
        os === "Android" &&
        isMicrosoftAppsWebView(userAgent)
      ) {
        navigator.clipboard.writeText(
          `xfa:transaction/${transaction.transactionId}`,
        );
      }

      const redirectUrl = new URL(
        "/token-mobile",
        process.env.REACT_APP_MOBILE_BASEURL,
      );
      redirectUrl.searchParams.set("transactionId", transaction.transactionId);

      if (window.location.href.includes("token-mobile")) {
        redirectUrl.searchParams.set("appNotInstalled", "true");
      }

      window.location.replace(redirectUrl.toString());
    },
    [userAgent, navigator.clipboard],
  );

  const handleDesktopTransaction = useCallback(
    async (
      transaction: Transaction,
      os: OS,
      supported: boolean | undefined,
      force: boolean | undefined,
    ) => {
      const isSupportedDesktop =
        supportedDesktopOSes.includes(os) || (os === "UNIX" && supported);

      if (isSupportedDesktop) {
        if (
          isBraveOrSafari(navigator) &&
          transaction.requestJoinOrganization === true
        ) {
          console.log("Requesting join organizaiton");
          setTokenStatus("Checking");
          return;
        }

        if (
          (isBraveOrSafari(navigator) && force) ||
          !isBraveOrSafari(navigator)
        ) {
          try {
            setCompletingTransaction(true);
            const reply = await clientApi.completeTransaction(
              transaction.transactionId,
            );

            updateTransaction(transaction.transactionId);
            setCompletingTransaction(false);

            if (reply.message?.includes("error")) {
              setTokenStatus("Error");
              setError(reply.message);
              throw new Error(reply.message);
            }
          } catch (error) {
            setTokenStatus("Error");
            console.log("error: ", error);
            setError(t("Token.errors.sendingTransactionToExtensionFailed"));
            setCompletingTransaction(false);
          }
        } else {
          setTokenStatus("Error");
          setError(t("Device not supported"));
        }
      }
    },
    [
      setCompletingTransaction,
      updateTransaction,
      t,
      setError,
      setTokenStatus,
      clientApi,
      navigator,
    ],
  );

  return useCallback(
    async (
      transaction: Transaction,
      supported: boolean | undefined,
      doRecheck: boolean | undefined,
      force: boolean | undefined,
    ) => {
      const os = checkOS(window.navigator);
      if (!os) {
        setTokenStatus("Error");
        setError(t("OS not known"));
        return;
      }

      if (!transaction) {
        setTokenStatus("Error");
        setError(t("Transaction not present"));
        return;
      }

      if (
        transaction.status === "FILTERED" ||
        transaction.status === "GRANTED"
      ) {
        endTransaction(transaction);
        return;
      }

      if (supportedMobileOSes.includes(os)) {
        handleMobileTransaction(transaction, os);
      } else {
        if (isClientAvailable && !isProtocolHandlerAvailable) {
          try {
            await handleDesktopTransaction(transaction, os, supported, false);
          } catch (error) {
            console.error("Error in handleDesktopTransaction:", error);
            setTokenStatus("Error");
            setError(t("An error occurred while completing the transaction"));
          }
        } else if (isClientAvailable && isProtocolHandlerAvailable) {
          if (force) {
            try {
              await handleDesktopTransaction(transaction, os, supported, force);
            } catch (error) {
              console.error("Error in handleDesktopTransaction:", error);
              setTokenStatus("Error");
              setError(t("An error occurred while completing the transaction"));
            }
          } else if (transaction.doRecheck === undefined && doRecheck) {
            handleRecheck({
              transaction,
              setLoading: () => setTokenStatus("Checking"),
              setError,
            })();
          }
        }
      }
    },
    [
      t,
      setTokenStatus,
      setError,
      endTransaction,
      handleDesktopTransaction,
      handleMobileTransaction,
      isClientAvailable,
      isProtocolHandlerAvailable,
    ],
  );
};
