import {
  // eslint-disable-next-line camelcase
  _token__transactionId__post_request,
  DeviceApi,
  Transaction,
} from "../../API/XFA_DEVICE_API";
import { deviceApiConfig } from "../../Config";
// eslint-disable-next-line camelcase
import action = _token__transactionId__post_request.action;

export const handleRecheck = ({
  transaction,
  setLoading,
  setError,
}: {
  transaction: Transaction;
  setLoading: () => void;
  setError: (error: any) => void;
}) => {
  const deviceClient = new DeviceApi(deviceApiConfig);
  deviceClient.request.config.CREDENTIALS = "include";
  deviceClient.request.config.WITH_CREDENTIALS = true;

  const handleRecheck = () => {
    if (!transaction?.transactionId) {
      console.error("Transaction ID is required for refreshing");
      return;
    }
    setLoading();
    deviceClient.default
      .postToken1(transaction.transactionId, { action: action.RECHECK })
      .catch((error) => {
        setError(error);
      });
  };

  return handleRecheck;
};
