import { Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

//Icons
import { goToTokenTransaction } from "../../Navigation/Navigation";
import { useNavigate } from "react-router-dom";
import XFAIcon from "../../../images/XFA_woordmerk_donkergrijs.svg";
//css
import "../../General/general.css";
import { useClientApiContext } from "../../Extension/ClientApiContext";

export interface ExtensionInstalledSectionProps {
  browser: any;
  ongoingTransaction: boolean | undefined;
}

const ExtensionInstalledSection: React.FC<ExtensionInstalledSectionProps> = ({
  browser,
  ongoingTransaction,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clientApi, isNativeClientAvailable, isProtocolHandlerAvailable } =
    useClientApiContext();

  return (
    <div className="general">
      <img src={XFAIcon} alt="XFAIcon" className="squaredIcon" />
      <Typography className="header">{t(`Installation.finished1`)}</Typography>
      <Typography className="header">
        {isNativeClientAvailable || isProtocolHandlerAvailable
          ? t(`Installation.finished2-app`)
          : t(`Installation.finished2`)}
      </Typography>
      {!isProtocolHandlerAvailable && (
        <Typography className="body1">
          {t("Installation.finishedDescription", { name: browser })}
        </Typography>
      )}
      {ongoingTransaction ? (
        <Button
          variant="contained"
          className="black-button"
          onClick={() => {
            goToTokenTransaction(navigate);
          }}
        >
          <Typography>{t("Installation.returnToTransaction")}</Typography>
        </Button>
      ) : (
        !isProtocolHandlerAvailable && (
          <Button
            variant="contained"
            className="black-button"
            onClick={() => {
              clientApi.openExtension();
            }}
          >
            <Typography>
              {isNativeClientAvailable
                ? t("Installation.openApplication")
                : t("Installation.openExtension")}
            </Typography>
          </Button>
        )
      )}
    </div>
  );
};

export default ExtensionInstalledSection;
