import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import languageEN from "./locale/en/translate.json";
import languageNL from "./locale/nl/translate.json";
import languageFR from "./locale/fr/translate.json";
import languageDE from "./locale/de/translate.json";
import languageES from "./locale/es/translate.json";

const I18n = i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: languageEN,
      nl: languageNL,
      fr: languageFR,
      de: languageDE,
      es: languageES,
    },
    fallbackLng: "en",
    debug: true,
    interpolation: {
      escapeValue: false, // Not needed for react as it escapes by default
    },
    pluralSeparator: "_",
  });

export default I18n;
