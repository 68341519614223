import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  TokenStatus,
  checkOS,
  isBraveOrSafari,
  supportedMobileOSes,
} from "../System/System";
import { useNavigate, useSearchParams } from "react-router-dom";
import useTransaction from "../Token/hooks/useTransaction";
import TopBar from "../General/TopBar";
import XFAIcon from "../../images/XFA_woordmerk_donkergrijs.svg";
import CheckingSection from "../Token/sections/CheckingSection";

import { ClientMissingSection } from "../Token/sections/ClientMissingSection";
import {
  goToInstallationStep,
  redirectWithProtocolOnIos,
} from "../Token/utils/redirectHelpers";

import { DeviceApi } from "../API/XFA_DEVICE_API";
import { deviceApiConfig } from "../Config";
import { useCompleteTransaction } from "../Token/hooks/useCompleteTransaction";
import { useEndTransaction } from "../Token/hooks/useEndTransaction";
import { useDeviceSupport } from "../Token/hooks/useDeviceSupport";
import { ErrorSection } from "../Token/sections/ErrorSection";
import { ConnectSection } from "../Installation/sections/ConnectSection";
import { transactionCompletion } from "../Token/utils/transactionCompletion";
import { VerifiedSection } from "../Token/sections/SuccessSection";
import { WrongDeviceSection } from "./sections/WrongDeviceSection";
import { useClientApiContext } from "../Extension/ClientApiContext";

export interface InstallationProps {
  navigator: any;
  browser: any;
}

const Verify: React.FC<InstallationProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const deviceClient = new DeviceApi(deviceApiConfig);
  deviceClient.request.config.CREDENTIALS = "include";
  deviceClient.request.config.WITH_CREDENTIALS = true;

  const { transaction, updateTransaction } = useTransaction(
    props.navigator,
    true,
  );
  const { isClientAvailable, isProtocolHandlerAvailable, recheck } =
    useClientApiContext();
  const { browser, supported } = useDeviceSupport(
    props.navigator,
    isClientAvailable,
  );

  const os = checkOS(props.navigator);
  const braveOrSafari = isBraveOrSafari(props.navigator);

  const [state, setState] = useState({
    deviceId: searchParams.get("deviceId") || undefined,
    policyId: searchParams.get("policyId") || undefined,
    organizationId: searchParams.get("organizationId") || undefined,
    appNotInstalled: searchParams.get("appNotInstalled") === "true",
    verificationStatus: "Checking" as TokenStatus,
    error: "",
  });

  const [hasVerifiedDevice, setHasVerifiedDevice] = useState(false);

  const {
    deviceId,
    policyId,
    organizationId,
    verificationStatus,
    error,
    appNotInstalled,
  } = state;

  const endTransaction = useEndTransaction(
    (status) => setState((prev) => ({ ...prev, verificationStatus: status })),
    () => {},
  );

  const completeTransaction = useCompleteTransaction(
    (status) => setState((prev) => ({ ...prev, verificationStatus: status })),
    props.navigator,
    (err) => setState((prev) => ({ ...prev, error: err })),
    updateTransaction,
    () => {},
    endTransaction,
  );

  const verifyDevice = useCallback(
    (newVerificationDevice: boolean) => {
      if (hasVerifiedDevice || deviceId === undefined || deviceId === "")
        return;
      setState((prev) => ({ ...prev, verificationStatus: "Checking" }));

      deviceClient.default
        .getVerify(deviceId, organizationId!, newVerificationDevice, policyId)
        .then((response) => {
          updateTransaction(response?.transactionId);
          if (response?.verification) {
            if (
              os &&
              os !== response.verification.suggestedOSName &&
              !newVerificationDevice
            ) {
              setState((prev) => ({
                ...prev,
                verificationStatus: "WrongDevice",
              }));
              return;
            } else if (!newVerificationDevice) {
              setState((prev) => ({
                ...prev,
                verificationStatus: "Confirmation",
              }));
            }
          }
          completeTransaction(response, supported, false, false);
          setHasVerifiedDevice(true);
        })
        .catch((err) => {
          if (err.status === 404 || err.status === 400) {
            setState((prev) => ({
              ...prev,
              error: t("verification.linkExpired"),
              verificationStatus: "Error",
            }));
          } else {
            setState((prev) => ({
              ...prev,
              error: err.message,
              verificationStatus: "Error",
            }));
          }
        });
    },
    [
      deviceId,
      deviceClient.default,
      organizationId,
      policyId,
      supported,
      updateTransaction,
      completeTransaction,
      hasVerifiedDevice,
      os,
      t,
    ],
  );

  useEffect(() => {
    if (!deviceId || !organizationId || hasVerifiedDevice) return;
    verifyDevice(false);
    // eslint-disable-next-line
  }, [deviceId, organizationId, hasVerifiedDevice]);

  useEffect(
    () => {
      if (transaction?.verification && verificationStatus !== "WrongDevice") {
        transactionCompletion(
          transaction,
          supported,
          isClientAvailable,
          false,
          (status) => {
            if (verificationStatus === status) return;
            setState((prev) => ({ ...prev, verificationStatus: status }));
          },
          verificationStatus,
          false,
          () => {},
          endTransaction,
          completeTransaction,
        );
      }
    },
    // eslint-disable-next-line
    [transaction, supported, isClientAvailable, endTransaction],
  );

  useEffect(() => {
    if (verificationStatus === "Checking" && braveOrSafari && transaction) {
      const interval = setInterval(() => {
        updateTransaction(transaction.transactionId);
      }, 1000);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [
    verificationStatus,
    props.navigator,
    transaction?.transactionId,
    updateTransaction,
  ]);

  // Determine which section to show based on verificationStatus and conditions
  const showConnectSection = verificationStatus === "ClientMissing";
  const showErrorSection = verificationStatus === "Error";
  const showVerifiedSection = verificationStatus === "OK";
  const showWrongDeviceSection = verificationStatus === "WrongDevice";
  const showClientMissingSection =
    verificationStatus === "Confirmation" &&
    !showConnectSection &&
    supported === true &&
    isClientAvailable;

  return (
    <div className="root">
      <TopBar transaction={transaction} signIn={false} />
      <div className="content-container">
        <div className="content-token">
          {!showConnectSection && (
            <div className="general">
              <img src={XFAIcon} alt="XFAIcon" className="xfaIcon" />
            </div>
          )}
          {verificationStatus === "Checking" && (
            <CheckingSection
              skip={() => {}}
              transactionIsSkippable={false}
              deviceCheck
              transaction={transaction}
              recheck={() => {
                recheck(true, transaction?.transactionId);
              }}
              shouldRetry={braveOrSafari || isProtocolHandlerAvailable}
              goToInstallation={() => {
                goToInstallationStep(
                  transaction!,
                  os!,
                  browser,
                  navigate,
                  true,
                );
              }}
            />
          )}
          {showConnectSection && (
            <ConnectSection
              goToInstallation={async () => {
                if (!transaction) return;
                goToInstallationStep(transaction, os!, browser, navigate, true);
              }}
              organization={transaction?.organization?.name}
              organizationIconUrl={transaction?.organization?.logoUrl}
              mobile={(os && supportedMobileOSes.includes(os)) ?? false}
              invited={false}
              isSkippable={false}
              skip={() => {}}
              demo={false}
              recheck={() => {
                recheck(true, transaction?.transactionId);
              }}
            />
          )}
          {showWrongDeviceSection && (
            <WrongDeviceSection verifyDevice={() => verifyDevice(true)} />
          )}
          {showClientMissingSection && (
            <ClientMissingSection
              completeTransaction={() => {
                if (!transaction) return;
                completeTransaction(transaction, supported, false, true);
              }}
              goToInstallation={async () => {
                if (!transaction) return;
                goToInstallationStep(transaction, os!, props.browser, navigate);
              }}
              customProtocolIOS={() => redirectWithProtocolOnIos(transaction!)}
              skip={() => {}}
              transaction={transaction}
              refreshTransaction={() => {
                updateTransaction(transaction?.transactionId!);
              }}
              transactionIsSkippable={false}
              tokenStatus={verificationStatus}
              devicePreviouslyAffiliated={false}
              os={os}
              organization={transaction?.organization?.name}
              appNotInstalled={appNotInstalled}
            />
          )}
          {showVerifiedSection &&
            transaction &&
            transaction.organization &&
            transaction.organization.name && (
              <VerifiedSection
                organizationName={transaction.organization.name}
              />
            )}
          {showErrorSection && (
            <ErrorSection
              recheck={
                error === t("verification.organizationError")
                  ? undefined
                  : () => {
                      setState((prev) => ({
                        ...prev,
                        verificationStatus: "Checking",
                      }));
                      recheck(true, transaction?.transactionId);
                    }
              }
              error={error}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Verify;
