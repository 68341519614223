/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Trusted Platform Module, a hardware-based security solution (important to check on Windows and Linux)
 */
export type TPM = {
    /**
     * Trusted Platform Module is present on the device
     */
    present?: boolean;
    /**
     * Trusted Platform Module is active on the device
     */
    active?: boolean;
    /**
     * Version of Trusted Platform Module
     */
    version?: TPM.version;
};
export namespace TPM {
    /**
     * Version of Trusted Platform Module
     */
    export enum version {
        _1_2 = '1.2',
        _2_0 = '2.0',
    }
}

