import { Typography, Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import EmailIcon from "@mui/icons-material/EmailOutlined";

export const MfaWaitForConfirmation: React.FC<{
  waitForConfirmation: boolean;
  waitForEmailConfirmation: boolean;
  firstMfaDevice: boolean;
  onSendEmail: () => void;
}> = ({
  waitForConfirmation,
  waitForEmailConfirmation,
  firstMfaDevice,
  onSendEmail,
}) => {
  const { t } = useTranslation();
  return waitForConfirmation && !waitForEmailConfirmation ? (
    <div>
      <div>
        {firstMfaDevice ? (
          t("mfa.checkingFirstDevice")
        ) : (
          <div>
            <Typography className="mfa-header-h2">
              {t("mfa.trustedDeviceTitle")}
            </Typography>
            <div
              dangerouslySetInnerHTML={{
                __html: t("mfa.checking"),
              }}
            />
          </div>
        )}
      </div>
      {!firstMfaDevice ? (
        <div>
          <Typography className="mfa-header-h2 second-title">
            {t("mfa.emailTitle")}
          </Typography>
          <Button
            variant="contained"
            className="grey-button email-button"
            size="large"
            startIcon={<EmailIcon className="emailIcon" />}
            onClick={onSendEmail}
          >
            {t("mfa.emailButton")}
          </Button>
        </div>
      ) : null}
    </div>
  ) : waitForConfirmation && waitForEmailConfirmation ? (
    <div>
      <div>
        <Typography className="mfa-header-h2">
          {t("mfa.checkEmailTitle")}
        </Typography>
        {t("mfa.checkEmailContent")}
      </div>
    </div>
  ) : (
    <></>
  );
};
