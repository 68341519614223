export enum IframePath {
  OS_UPDATES = "os-updates",
  DISK_ENCRYPTION = "disk-encryption",
  AUTO_UPDATE = "autoupdate",
  BROWSER_UPDATE = "browser",
  NATIVE_CLIENT = "native-client",
  ANTIVIRUS = "antivirus",
  AUTHENTICATION = "authentication",
  REBOOT = "regular-reboot",
  SECURE_BOOT = "secure-boot",
  INTEGRITY_PROTECTION = "integrityprotection",
  FIND_MY_DEVICE = "findmydevice",
  FIREWALL = "firewall",
  CHROME_SAFE_BROWSING = "chromesafebrowsing",
  CHROME_DNT = "chromedonottrack",
  FIREFOX_ENHANCED_TRACKING_PROTECTION = "firefoxenhancedtrackingprotection",
  FIREFOX_DNS_OVER_HTTPS = "firefoxdnsoverhttps",
  FIREFOX_HTTPS_ONLY = "firefoxhttpsonly",
  SCREENSHARING = "screensharing",
  REMOTE_LOGIN = "remotelogin",
  REMOTE_SCRIPTING = "remotescripting",
}

export enum IframeOS {
  WINDOWS = "Windows",
  MACOS = "macOS",
  LINUX = "Linux",
  UNSUPPORTED = "unsupported",
}

export enum IframeBrowser {
  CHROME = "Chrome",
  FIREFOX = "Firefox",
  EDGE = "Edge",
  SAFARI = "Safari",
  UNSUPPORTED = "unsupported",
}

export type OS =
  | "Windows"
  | "macOS"
  | "UNIX"
  | "Linux"
  | "Android"
  | "iOS"
  | "iPadOS"
  | "ChromeOS";
export type TokenStatus =
  | "Checking"
  | "ClientMissing"
  | "Confirmation"
  | "OK"
  | "NOK"
  | "Error"
  | "WrongDevice";
export type Browser =
  | "Chrome"
  | "Edge"
  | "Safari"
  | "Firefox"
  | "Opera"
  | "IE"
  | "Brave";

export const supportedDesktopOSes = ["macOS", "Windows"];
export const supportedMobileOSes: string[] = ["iOS", "Android", "iPadOS"];
export const supportedIOSes: string[] = ["iOS", "iPadOS"];
export const supportedBrowsers = [
  "Chrome",
  "Firefox",
  "Edge",
  "Brave",
  "Safari",
];

export const protocolHandlerSupportedBrowsers = ["Brave", "Safari"];

const isIpad = () => {
  return (
    /iPad|Macintosh/i.test(navigator.userAgent) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 1
  );
};
export const checkOS = (navigator: any) => {
  if (navigator.appVersion.indexOf("Android") !== -1) return "Android" as OS;
  if (isIpad()) return "iPadOS" as OS;
  if (
    (navigator.appVersion.indexOf("Mobile") !== -1 &&
      navigator.appVersion.indexOf("Mac") !== -1) ||
    (navigator.appVersion.indexOf("iPhone") !== -1 &&
      navigator.appVersion.indexOf("Mac") !== -1)
  )
    return "iOS" as OS;
  if (navigator.appVersion.indexOf("Win") !== -1) return "Windows" as OS;
  if (navigator.appVersion.indexOf("Mac") !== -1) return "macOS" as OS;
  if (navigator.appVersion.indexOf("X11") !== -1) return "Linux" as OS;
  if (navigator.appVersion.indexOf("Linux") !== -1) return "Linux" as OS;
  if (navigator.appVersion.indexOf("CrOS") !== -1) return "ChromeOS" as OS;
};

export const isBraveOrSafari = (navigator: any) => {
  const browserString = checkBrowser(navigator);
  const os = checkOS(navigator);
  return (
    os !== undefined &&
    supportedDesktopOSes.includes(os) &&
    browserString !== undefined &&
    protocolHandlerSupportedBrowsers.includes(browserString)
  );
};

export const checkBrowser = (navigator: any) => {
  const ua = navigator.userAgent;
  let tem;
  let M =
    ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) ||
    [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return "IE" as Browser;
  }

  if (M[1] === "Chrome") {
    tem = ua.match(/\b(OPR|Edge|Edg)\/([0-9.]+)/);
    if (tem != null)
      if (tem[0] === "OPR") {
        return "Opera" as Browser;
      } else {
        return "Edge" as Browser;
      }
  }

  if (navigator.brave && navigator.brave.isBrave) {
    return "Brave" as Browser;
  }

  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);

  if (M[0] === "Chrome") {
    return "Chrome" as Browser;
  }
  if (M[0] === "Safari") {
    return "Safari" as Browser;
  }
  if (M[0] === "Firefox") {
    return "Firefox" as Browser;
  }
};

export function capitalizeFirstLetter(string: string) {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export const playStoreLink =
  "https://play.google.com/store/apps/details?id=tech.xfa.xfa_native_mobile_application";
export const appStoreLink = "https://apps.apple.com/be/app/xfa/id1630086940";
export const mozillaExtensionLink =
  "https://addons.mozilla.org/en-US/firefox/addon/xfa-securing-your-device/";
export const chromeExtensionLink =
  "https://chromewebstore.google.com/detail/xfa-discover-true-optimal/ajobakkejbhbmiiodbcmobomohngjdhc";
export const edgeExtensionLink =
  "https://microsoftedge.microsoft.com/addons/detail/xfa-securing-your-device/ikhelogcjkfnfccibbceakadlkbilhbg";

export const getBrowser = (browser?: Browser) => {
  switch (browser) {
    case "Chrome":
      return IframeBrowser.CHROME;
    case "Firefox":
      return IframeBrowser.FIREFOX;
    case "Edge":
      return IframeBrowser.EDGE;
    case "Safari":
      return IframeBrowser.SAFARI;
    default:
      // TODO: Implement for all browsers
      return IframeBrowser.UNSUPPORTED;
  }
};

export const getOSOfPlatform = (platform: OS | null) => {
  switch (platform) {
    case "Windows":
      return IframeOS.WINDOWS;
    case "macOS":
      return IframeOS.MACOS;
    case "Linux":
      return IframeOS.LINUX;
    default:
      return IframeOS.UNSUPPORTED;
  }
};

export const parseWindows = (
  osName: string,
  osVersion: string | undefined,
  output: "full" | "short",
) => {
  if (!osVersion) {
    return osName;
  }
  const splittedVersion = osVersion.split(".");
  if (splittedVersion.length < 3 || splittedVersion.length > 4) {
    return osName + " " + osVersion;
  }
  const build = Number(splittedVersion[2]);
  if (build >= 22000) {
    return output === "short" ? "Windows 11" : "Windows 11 version 21H2";
  } else if (build === 19044) {
    return output === "short" ? "Windows 10" : "Windows 10 version 21H2";
  } else if (build === 19043) {
    return output === "short" ? "Windows 10" : "Windows 10 version 21H1";
  } else if (build === 19042) {
    return output === "short" ? "Windows 10" : "Windows 10 version 20H2";
  } else if (build === 19041) {
    return output === "short" ? "Windows 10" : "Windows 10 version 2004";
  } else if (build === 18363) {
    return output === "short" ? "Windows 10" : "Windows 10 version 1909";
  } else if (build === 18362) {
    return output === "short" ? "Windows 10" : "Windows 10 version 1903";
  } else if (build === 17763) {
    return output === "short" ? "Windows 10" : "Windows 10 version 1809";
  } else if (build === 17134) {
    return output === "short" ? "Windows 10" : "Windows 10 version 1803";
  } else if (build === 16299) {
    return output === "short" ? "Windows 10" : "Windows 10 version 1709";
  } else if (build === 15063) {
    return output === "short" ? "Windows 10" : "Windows 10 version 1703";
  } else if (build === 14393) {
    return output === "short" ? "Windows 10" : "Windows 10 version 1607";
  } else if (build === 10586) {
    return output === "short" ? "Windows 10" : "Windows 10 version 1511";
  } else if (build === 10240) {
    return output === "short" ? "Windows 10" : "Windows 10 version 1507";
  } else if (build === 9600) {
    return "Windows 8.1";
  } else if (build === 9200) {
    return "Windows 8";
  } else if (build === 7601) {
    return "Windows 7";
  } else {
    return output === "short" ? "Windows" : osName + " " + osVersion;
  }
};

export const detectWindowsVersion = () => {
  const { userAgent } = navigator;
  const regex = /Windows NT (\d+\.\d+)\.(\d+)/;
  const match = userAgent.match(regex);
  if (match) {
    const osVersion = `${match[1]}.${match[2]}`;
    return parseWindows("Windows", osVersion, "short");
  }
  return "Windows";
};
