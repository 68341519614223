import React, { useEffect } from "react";
import * as ReactDOMClient from "react-dom/client";
import "./components/I18n/I18n";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import Webfont from "webfontloader";
import "@fontsource/manrope";

import "./index.css";

import { getBrowser } from "./components/Browser/Browser";

import * as Sentry from "@sentry/react";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";

//preload fonts (less flicker)
Webfont.load({
  google: {
    families: ["Manrope:400,500,600,700"],
  },
});

Sentry.init({
  dsn: "https://f03e29fc59964a35a68635e61fbf2cc5@o1126213.ingest.sentry.io/6166971",
  release: process.env.REACT_APP_SENTRY_RELEASE,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  //tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

// Ensure the root element exists in your HTML file
const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");

// Create a root and render the App component
const root = ReactDOMClient.createRoot(rootElement);
root.render(
  <Sentry.ErrorBoundary>
    <App navigator={navigator} browser={getBrowser()} />
  </Sentry.ErrorBoundary>,
);

/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
