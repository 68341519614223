import {
  // eslint-disable-next-line camelcase
  _token__transactionId__post_request,
  DeviceApi,
} from "../../API/XFA_DEVICE_API";
import { deviceApiConfig } from "../../Config";
// eslint-disable-next-line camelcase
import action = _token__transactionId__post_request.action;

export const handleRequestJoinOrganization = ({
  transactionId,
}: {
  transactionId: string;
}) => {
  const deviceClient = new DeviceApi(deviceApiConfig);
  deviceClient.request.config.CREDENTIALS = "include";
  deviceClient.request.config.WITH_CREDENTIALS = true;

  const handleRequestJoinOrganization = () => {
    if (!transactionId) {
      console.error("Transaction ID is required for refreshing");
      return;
    }
    deviceClient.default
      .postToken1(transactionId, { action: action.REQUEST_JOIN_ORGANIZATION })
      .catch((error) => {
        throw new Error(error);
      });
  };

  return handleRequestJoinOrganization;
};
