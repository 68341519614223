import React, { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import "./PrivacyRuleButton.css";
import PrivacyIcon from "./images/privacy.png";
import RemoteControlIcon from "./images/remote-control.png";
import TimeIcon from "./images/time.png";
import HelpIcon from "./images/help.png";

interface PrivacyRuleButtonProps {
  iconName: "privacy" | "remote-control" | "time";
}

const PrivacyRuleButton: React.FC<PrivacyRuleButtonProps> = ({ iconName }) => {
  const { t } = useTranslation();
  const [tooltipPlacement, setTooltipPlacement] = useState<"top" | "right">(
    "right",
  );

  const iconData = {
    privacy: {
      icon: PrivacyIcon,
      tooltipTitle: t("Installation.privacy.tooltipTitle"),
      tooltipDescription: t("Installation.privacy.tooltipDescription"),
    },
    "remote-control": {
      icon: RemoteControlIcon,
      tooltipTitle: t("Installation.remoteControl.tooltipTitle"),
      tooltipDescription: t("Installation.remoteControl.tooltipDescription"),
    },
    time: {
      icon: TimeIcon,
      tooltipTitle: t("Installation.time.tooltipTitle"),
      tooltipDescription: t("Installation.time.tooltipDescription"),
    },
  };

  const { icon, tooltipTitle, tooltipDescription } = iconData[iconName];

  // Update place of tooltip on window resize
  useEffect(() => {
    const updateTooltipPlacement = () => {
      setTooltipPlacement(window.innerWidth < 768 ? "top" : "right");
    };

    window.addEventListener("resize", updateTooltipPlacement);
    updateTooltipPlacement();

    return () => {
      window.removeEventListener("resize", updateTooltipPlacement);
    };
  }, []);

  const CustomTooltipContent = () => (
    <React.Fragment>
      <Typography color="inherit" variant="subtitle1" className="tooltipHeader">
        {tooltipTitle}
      </Typography>
      <Typography
        color="inherit"
        variant="body2"
        className="tooltipDescription"
      >
        {tooltipDescription}
      </Typography>
    </React.Fragment>
  );

  return (
    <div className="privacyButtonContainer">
      <Tooltip
        title={<CustomTooltipContent />}
        placement={tooltipPlacement}
        arrow
      >
        <div className="privacyButton">
          <div className="contentWithIcon">
            <img src={icon} className="privacy-icon" alt={tooltipTitle} />
            <Typography className="privacy-text">{tooltipTitle}</Typography>
          </div>
          <img src={HelpIcon} className="help-icon" alt="Help" />
        </div>
      </Tooltip>
    </div>
  );
};

export default PrivacyRuleButton;
