/* global chrome */
import { useEffect, useState, useCallback, useMemo } from "react";
import {
  checkOS,
  isBraveOrSafari,
  OS,
  supportedMobileOSes,
} from "../System/System";
import { NativeClientApi } from "../API/XFA_NATIVE_CLIENT";
import {
  checkExtensionInstalled,
  getCredentialID,
  createCredential,
  getCredential,
  openExtension as extensionOpenExtension,
  checkDeviceAffiliatedWithOrganization,
  sendCompleteTransactionToExtension,
  sendInvitationsToExtension,
} from "./Extension";
import { Invitation } from "../Invitations/Invitation";
import {
  LoginBeginResponse,
  LoginFinishRequest,
  RegisterBeginResponse,
  RegisterFinishRequest,
} from "../API/XFA_DEVICE_API";
import { handleRequestJoinOrganization } from "../Token/utils/handleRequestJoinOrganization";
import { useDeviceSupport } from "../Token/hooks/useDeviceSupport";

const useClientApi = (navigator: Navigator, browser: any) => {
  const [isExtensionInstalled, setIsExtensionInstalled] = useState<
    boolean | undefined
  >();
  const { browser: localBrowser } = useDeviceSupport(navigator, undefined);
  const [isClientAvailable, setIsClientAvailable] = useState<boolean>(false);
  const [isNativeClientAvailable, setIsNativeClientAvailable] =
    useState<boolean>(false);
  const [isProtocolHandlerAvailable, setIsProtocolHandlerAvailable] =
    useState<boolean>(false);
  const [checking, setChecking] = useState<boolean>(false);
  const [os, setOS] = useState<OS>();
  const [didCheckNativeClient, setDidCheckNativeClient] =
    useState<boolean>(false);
  const [useProtocolHandler, setUseProtocolHandler] = useState<boolean>(false);
  const [isRechecking, setIsRechecking] = useState<boolean>(false);
  const [silent, setSilent] = useState<boolean>(false);
  const [transactionId, setTransactionId] = useState<string>("");

  const deviceClient = useMemo(() => new NativeClientApi(), []);
  const braveOrSafari = useMemo(() => isBraveOrSafari(navigator), [navigator]);

  /**
   * Helper to invoke protocol handlers using an iframe.
   */

  const invokeProtocolHandler = useCallback(
    async (url: string): Promise<boolean> => {
      return new Promise((resolve) => {
        let resolved = false;

        const handleFocus = async () => {
          if (!resolved) {
            resolved = true;
            window.removeEventListener("blur", handleFocus);
            if (iframe.parentNode) {
              document.body.removeChild(iframe);
            }
            resolve(true);
          }
        };

        window.addEventListener("blur", handleFocus);

        const iframe = document.createElement("iframe");
        iframe.style.display = "none";
        document.body.appendChild(iframe);
        iframe.src = url;

        setTimeout(() => {
          if (!resolved) {
            resolved = true;
            window.removeEventListener("blur", handleFocus);
            if (iframe.parentNode) {
              document.body.removeChild(iframe);
            }
            resolve(false);
          }
        }, 3000);
      });
    },
    [],
  );

  /**
   * Check if the native client is available.
   */
  const checkNativeClient = useCallback(async () => {
    if (braveOrSafari) {
      setChecking(false);
      setIsNativeClientAvailable(false);
      setDidCheckNativeClient(true);
      return;
    }
    try {
      const response = await deviceClient.default.getInstalled();
      setIsNativeClientAvailable(response);
      setIsClientAvailable(
        response || isExtensionInstalled || isProtocolHandlerAvailable,
      );
    } catch (error) {
      console.error("Error checking native client", error);
      setIsNativeClientAvailable(false);
      setIsClientAvailable(isExtensionInstalled || isProtocolHandlerAvailable);
    } finally {
      setChecking(false);
      setDidCheckNativeClient(true);
    }
  }, [
    deviceClient,
    isExtensionInstalled,
    isProtocolHandlerAvailable,
    braveOrSafari,
  ]);

  /**
   * Check if the protocol handler is available.
   */
  const checkProtocolHandler = useCallback(
    async (transactionId: string) => {
      let protocolUrl = `xfatech://installed`;

      const urlParams = new URLSearchParams(window.location.search);
      const verification = urlParams.get("verification") === "true";

      if (transactionId) {
        protocolUrl = `xfatech://complete-transaction?transaction_id=${encodeURIComponent(
          transactionId,
        )}&browser=${encodeURIComponent(localBrowser ?? browser)}&affiliate=${verification}`;
      }
      console.debug("testing:protocolUrl:", protocolUrl);
      const success = await invokeProtocolHandler(protocolUrl);
      setIsProtocolHandlerAvailable(success);
      setIsClientAvailable(success || isExtensionInstalled || false);
      setIsRechecking(false);
      setChecking(false);
    },
    [isExtensionInstalled, invokeProtocolHandler, localBrowser, browser],
  );

  /**
   * Runs checks for extension, native client, and protocol handler availability.
   */
  useEffect(() => {
    const runChecks = async () => {
      setChecking(true);
      if (!os) return;

      if (supportedMobileOSes.includes(os)) {
        setIsExtensionInstalled(false);
        setIsNativeClientAvailable(false);
        setIsProtocolHandlerAvailable(false);
        setIsClientAvailable(false);
        setChecking(false);
        return;
      }

      if (braveOrSafari) {
        handleBraveOrSafariRecheck();
        return;
      }

      if (!didCheckNativeClient && !isNativeClientAvailable) {
        await checkNativeClient();
      } else if (isExtensionInstalled === undefined) {
        checkExtensionInstalled(
          os,
          setChecking,
          setIsExtensionInstalled,
          navigator,
          browser as typeof chrome,
          window,
        );
        setChecking(false);
      } else if (
        !isExtensionInstalled &&
        !isNativeClientAvailable &&
        useProtocolHandler &&
        (isProtocolHandlerAvailable === undefined ||
          isProtocolHandlerAvailable === false)
      ) {
        await checkProtocolHandler(transactionId);
        setChecking(false);
      } else if (isExtensionInstalled) {
        setIsClientAvailable(true);
        setChecking(false);
      } else {
        setIsClientAvailable(false);
      }
      setChecking(false);
    };
    if (!isClientAvailable && !checking) runChecks();
    setChecking(false);
    // eslint-disable-next-line
  }, [
    os,
    navigator,
    browser,
    isExtensionInstalled,
    didCheckNativeClient,
    isNativeClientAvailable,
    isProtocolHandlerAvailable,
    useProtocolHandler,
    checkNativeClient,
    checkProtocolHandler,
    isClientAvailable,
    transactionId,
    braveOrSafari,
  ]);

  const handleBraveOrSafariRecheck = useCallback(async () => {
    if (useProtocolHandler) {
      await checkProtocolHandler(transactionId);
      setChecking(false);
    }
  }, [checkProtocolHandler, transactionId, useProtocolHandler]);

  /**
   * Rechecks the availability of extension, native client, and protocol handler.
   */
  const recheck = useCallback(
    (force: boolean, transactionId?: string, silent: boolean = false) => {
      if (!os) return;

      if (supportedMobileOSes.includes(os)) {
        setIsExtensionInstalled(false);
        setChecking(false);
        return;
      }

      if (force && transactionId) {
        setTransactionId(transactionId);
      }
      setIsClientAvailable(false);
      setSilent(silent);
      setIsExtensionInstalled(undefined);
      setDidCheckNativeClient(false);
      setIsProtocolHandlerAvailable(false);
      setIsClientAvailable(false);
      setUseProtocolHandler(force);
      if (!braveOrSafari) {
        checkNativeClient();
      }
    },
    [os, checkNativeClient, braveOrSafari],
  );

  /**
   * Handles refocusing on the desktop to recheck availability.
   */
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        recheck(false);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [recheck]);

  /**
   * Initializes OS detection.
   */
  useEffect(() => {
    setOS(checkOS(navigator));
  }, [navigator]);

  /**
   * API methods
   */
  const clientApi = {
    async getCredentialID(transactionId: string): Promise<any> {
      if (isExtensionInstalled) {
        return new Promise((resolve, reject) => {
          try {
            getCredentialID(browser, navigator, resolve);
          } catch (error) {
            reject(error);
          }
        });
      }
      if (isNativeClientAvailable) {
        const response = await deviceClient.default.getAuthenticatorGetCredId();
        return response?.credId;
      }
      throw new Error("No method available to get credential ID");
    },

    async openExtension(): Promise<void> {
      if (isExtensionInstalled) {
        extensionOpenExtension(browser, navigator);
      } else if (isNativeClientAvailable) {
        const protocolUrl = "xfatech://";
        window.location.href = protocolUrl;
      } else if (isProtocolHandlerAvailable) {
        const protocolUrl = "xfatech://open";
        await invokeProtocolHandler(protocolUrl);
      } else {
        throw new Error("No method available to open extension or client");
      }
    },

    async createCredential(
      body: RegisterBeginResponse,
    ): Promise<RegisterFinishRequest> {
      if (isExtensionInstalled)
        return new Promise((resolve, reject) => {
          try {
            createCredential(browser, navigator, resolve, body);
          } catch (error) {
            reject(error);
          }
        });
      if (isNativeClientAvailable)
        return deviceClient.default.postAuthenticatorCreate(body);
      throw new Error("No method available to create credential");
    },

    async getCredential(body: LoginBeginResponse): Promise<LoginFinishRequest> {
      if (isExtensionInstalled)
        return new Promise((resolve, reject) => {
          try {
            getCredential(browser, navigator, resolve, body);
          } catch (error) {
            reject(error);
          }
        });
      if (isNativeClientAvailable)
        return deviceClient.default.postAuthenticatorGet(body);
      throw new Error("No method available to get credential");
    },

    async completeTransaction(transactionId: string): Promise<any> {
      if (isExtensionInstalled) {
        return new Promise((resolve, reject) => {
          try {
            sendCompleteTransactionToExtension(
              browser,
              navigator,
              transactionId,
              resolve,
            );
          } catch (error) {
            reject(error);
          }
        });
      }
      if (isNativeClientAvailable) {
        try {
          const response = await deviceClient.default.putCompleteTransaction(
            transactionId,
            localBrowser ?? browser,
          );
          return response;
        } catch (error) {
          console.error("Error completing transaction", error);
          throw error;
        }
      }
      if (isProtocolHandlerAvailable && braveOrSafari) {
        handleRequestJoinOrganization({ transactionId })();
        return true;
      }
      return true;
    },

    async checkDeviceAffiliatedWithOrganization(
      organizationId: string,
    ): Promise<any> {
      if (isExtensionInstalled) {
        return new Promise((resolve, reject) => {
          try {
            checkDeviceAffiliatedWithOrganization(
              browser,
              navigator,
              organizationId,
              resolve,
            );
          } catch (error) {
            reject(error);
          }
        });
      }
      if (isNativeClientAvailable) {
        const response =
          await deviceClient.default.getAffiliated(organizationId);
        return response?.affiliated === true;
      }
      throw new Error("No method available to check affiliation");
    },

    async sendInvitations(
      invitations: Invitation[],
      silent: boolean,
    ): Promise<any> {
      if (isExtensionInstalled) {
        return new Promise((resolve, reject) => {
          try {
            sendInvitationsToExtension(
              browser,
              navigator,
              invitations,
              silent,
              resolve,
            );
          } catch (error) {
            reject(error);
          }
        });
      }
      if (isNativeClientAvailable) {
        try {
          await Promise.all(
            invitations
              .filter(
                (invitation, index, self) =>
                  index === self.findIndex((i) => i.token === invitation.token),
              )
              .map((invitation) =>
                deviceClient.default.getInvitation(invitation.token),
              ),
          );
          return { ok: true };
        } catch (error) {
          console.error("Error sending invitations", error);
          return { ok: false };
        }
      }
      if (isProtocolHandlerAvailable || braveOrSafari) {
        const protocolUrl = `xfatech://invitation?invitation_token=${invitations[0].token}`;
        const success = await invokeProtocolHandler(protocolUrl);
        setIsProtocolHandlerAvailable(success);
        setIsClientAvailable(success || isExtensionInstalled || false);
        return { success: true };
      }
      throw new Error("No method available to send invitations");
    },
  };

  return {
    clientApi,
    checking,
    isClientAvailable,
    isExtensionInstalled,
    isNativeClientAvailable,
    isProtocolHandlerAvailable,
    recheck,
    silent,
    isRechecking,
  };
};

export default useClientApi;
