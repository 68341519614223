import React from "react";
import "./Decisions.css";
import { Transaction } from "../../../API/XFA_DEVICE_API";
import { useTranslation } from "react-i18next";
import NotNowButton from "../../../General/NotNowButton";
import StatusDecisionListItem from "./StatusDecisionListItem";
import { Button, CircularProgress, Typography } from "@mui/material";
import { IframePath } from "../../../System/System";

interface DecisionsProps {
  navigator: any;
  browser: any;
  transaction: Transaction;
  endTransaction: (transaction: Transaction) => void;
  refreshTransactionStatus: () => void;
  refreshingTransaction: boolean;
}

const Decisions: React.FC<DecisionsProps> = (props: DecisionsProps) => {
  const { t } = useTranslation();
  const oneHour = 60 * 60;

  const osTimeTillBlocked =
    props.transaction.decisions?.os_up_to_date?.time_till_blocked !== undefined
      ? Math.ceil(
          props.transaction.decisions?.os_up_to_date?.time_till_blocked /
            oneHour,
        )
      : undefined;

  const browserTimeTillBlocked =
    props.transaction.decisions?.browser_up_to_date?.time_till_blocked !==
    undefined
      ? Math.ceil(
          props.transaction.decisions?.browser_up_to_date?.time_till_blocked /
            oneHour,
        )
      : undefined;
  const isDecisionNotOk = React.useCallback((decision: any) => {
    return !(decision === undefined || decision?.status === "OK");
  }, []);
  const isDecisionBlocking = React.useCallback((decision: any) => {
    return decision !== undefined && decision?.status === "BLOCKING";
  }, []);
  const nativeClientNotInstalled = isDecisionNotOk(
    props.transaction.decisions?.installed,
  );
  const nativeClientNotInstalledIsBlocking = isDecisionBlocking(
    props.transaction.decisions?.installed,
  );
  const osNotUpToDate = isDecisionNotOk(
    props.transaction.decisions?.os_up_to_date,
  );
  const osNotUpToDateIsBlocking = isDecisionBlocking(
    props.transaction.decisions?.os_up_to_date,
  );

  const browserNotUpToDate = isDecisionNotOk(
    props.transaction.decisions?.browser_up_to_date,
  );
  const browserNotUpToDateIsBlocking = isDecisionBlocking(
    props.transaction.decisions?.browser_up_to_date,
  );
  const osAutoUpdateNotOK = isDecisionNotOk(
    props.transaction.decisions?.os_autoupdate,
  );
  const osAutoUpdateNotOKIsBlocking = isDecisionBlocking(
    props.transaction.decisions?.os_autoupdate,
  );
  const diskEncryptionNotOK = isDecisionNotOk(
    props.transaction.decisions?.disk_encryption,
  );
  const diskEncryptionNotOKIsBlocking = isDecisionBlocking(
    props.transaction.decisions?.disk_encryption,
  );
  const screenlockNotOK = isDecisionNotOk(
    props.transaction.decisions?.screenlock,
  );
  const screenlockNotOKIsBlocking = isDecisionBlocking(
    props.transaction.decisions?.screenlock,
  );
  const antivirusNotOK = isDecisionNotOk(
    props.transaction.decisions?.antivirus,
  );
  const antivirusNotOKIsBlocking = isDecisionBlocking(
    props.transaction.decisions?.antivirus,
  );
  const passwordmanagerNotOK = isDecisionNotOk(
    props.transaction.decisions?.passwordmanager,
  );
  const passwordmanagerNotOkIsBlocking = isDecisionBlocking(
    props.transaction.decisions?.passwordmanager,
  );
  const notAffiliated = isDecisionNotOk(
    props.transaction.decisions?.affiliated,
  );
  const notAffiliatedIsBlocking = isDecisionBlocking(
    props.transaction.decisions?.affiliated,
  );
  const notAllowedBrowserBetaVersion = isDecisionNotOk(
    props.transaction.decisions?.beta_browser_version,
  );
  const notAllowedBrowserBetaVersionIsBlocking = isDecisionBlocking(
    props.transaction.decisions?.beta_browser_version,
  );
  const notAllowedBrowserMajorVersion = isDecisionNotOk(
    props.transaction.decisions?.older_supported_browser_version,
  );
  const notAllowedBrowserMajorVersionIsBlocking = isDecisionBlocking(
    props.transaction.decisions?.older_supported_browser_version,
  );
  const notAllowedOsBetaVersion = isDecisionNotOk(
    props.transaction.decisions?.beta_os_version,
  );
  const notAllowedOsBetaVersionIsBlocking = isDecisionBlocking(
    props.transaction.decisions?.beta_os_version,
  );
  const notAllowedOsMajorVersion = isDecisionNotOk(
    props.transaction.decisions?.older_supported_os_version,
  );
  const notAllowedOsMajorVersionIsBlocking = isDecisionBlocking(
    props.transaction.decisions?.older_supported_os_version,
  );

  const passwordmanagerNotEnabled =
    props.transaction.decisions?.passwordmanager === undefined;

  const biometricsNotEnabled = isDecisionNotOk(
    props.transaction.decisions?.biometrics,
  );

  const biometricsNotEnabledIsBlocking = isDecisionBlocking(
    props.transaction.decisions?.biometrics,
  );

  const blocking = Object.entries(props.transaction.decisions || {}).some(
    ([key, decision]: [string, any]) =>
      decision?.status === "BLOCKING" && key !== "mfa",
  );

  return (
    <div className="contentDecisions">
      {nativeClientNotInstalled ? (
        <StatusDecisionListItem
          browser={props.browser}
          navigator={props.navigator}
          blocking={nativeClientNotInstalledIsBlocking}
          message={t("decisions.NativeClientNotInstalled")}
          buttonText={t("actions.install")}
          showModal={false}
        />
      ) : (
        <>
          {osNotUpToDate && (
            <StatusDecisionListItem
              navigator={props.navigator}
              browser={props.browser}
              blocking={osNotUpToDateIsBlocking}
              message={t("decisions.OSOutOfDate")}
              buttonText={t("actions.update")}
              showModal={true}
              timeTillBlocked={osTimeTillBlocked}
              path={IframePath.OS_UPDATES}
            />
          )}
          {osAutoUpdateNotOK && (
            <StatusDecisionListItem
              browser={props.browser}
              navigator={props.navigator}
              blocking={osAutoUpdateNotOKIsBlocking}
              message={t("decisions.osAutoUpdateNotOK")}
              buttonText={t("actions.fix")}
              showModal={true}
              path={IframePath.AUTO_UPDATE}
            />
          )}
          {browserNotUpToDate && (
            <StatusDecisionListItem
              browser={props.browser}
              navigator={props.navigator}
              blocking={browserNotUpToDateIsBlocking}
              message={t("decisions.BrowserOutOfDate")}
              buttonText={t("actions.update")}
              showModal={true}
              timeTillBlocked={browserTimeTillBlocked}
              path={IframePath.BROWSER_UPDATE}
            />
          )}
          {diskEncryptionNotOK && (
            <StatusDecisionListItem
              browser={props.browser}
              navigator={props.navigator}
              blocking={diskEncryptionNotOKIsBlocking}
              message={t("decisions.diskEncryptionNotOK")}
              buttonText={t("actions.enable")}
              showModal={true}
              path={IframePath.DISK_ENCRYPTION}
            />
          )}
          {screenlockNotOK && (
            <StatusDecisionListItem
              browser={props.browser}
              navigator={props.navigator}
              blocking={screenlockNotOKIsBlocking}
              message={t("decisions.screenlockNotOK")}
              buttonText={t("actions.activate")}
              showModal={true}
              path={IframePath.AUTHENTICATION}
            />
          )}
          {antivirusNotOK && (
            <StatusDecisionListItem
              browser={props.browser}
              navigator={props.navigator}
              blocking={antivirusNotOKIsBlocking}
              message={t("decisions.antivirusNotOK")}
              buttonText={t("actions.enable")}
              showModal={true}
              path={IframePath.ANTIVIRUS}
            />
          )}
          {notAffiliated && (
            <StatusDecisionListItem
              browser={props.browser}
              navigator={props.navigator}
              blocking={notAffiliatedIsBlocking}
              message={t("decisions.notAffiliated")}
              buttonText={t("actions.fix")}
              showModal={false}
            />
          )}
          {passwordmanagerNotOK && (
            <StatusDecisionListItem
              browser={props.browser}
              navigator={props.navigator}
              blocking={passwordmanagerNotOkIsBlocking}
              message={
                passwordmanagerNotEnabled
                  ? t("decisions.passwordmanagerNotEnabled")
                  : t("decisions.passwordmanagerNotOK")
              }
              buttonText={t("actions.fix")}
              showModal={false}
            />
          )}
          {notAllowedBrowserBetaVersion && (
            <StatusDecisionListItem
              browser={props.browser}
              navigator={props.navigator}
              blocking={notAllowedBrowserBetaVersionIsBlocking}
              message={t("decisions.browserBetaVersionWarning")}
              buttonText={t("actions.fix")}
              showModal={true}
              path={IframePath.BROWSER_UPDATE}
            />
          )}
          {notAllowedBrowserMajorVersion && (
            <StatusDecisionListItem
              browser={props.browser}
              navigator={props.navigator}
              blocking={notAllowedBrowserMajorVersionIsBlocking}
              message={t("decisions.browserMajorVersionWarning")}
              buttonText={t("actions.fix")}
              showModal={true}
              path={IframePath.BROWSER_UPDATE}
            />
          )}
          {notAllowedOsBetaVersion && (
            <StatusDecisionListItem
              browser={props.browser}
              navigator={props.navigator}
              blocking={notAllowedOsBetaVersionIsBlocking}
              message={t("decisions.osBetaVersionWarning")}
              buttonText={t("actions.fix")}
              showModal={true}
              path={IframePath.OS_UPDATES}
            />
          )}
          {notAllowedOsMajorVersion && (
            <StatusDecisionListItem
              browser={props.browser}
              navigator={props.navigator}
              blocking={notAllowedOsMajorVersionIsBlocking}
              message={t("decisions.osMajorVersionWarning")}
              buttonText={t("actions.fix")}
              showModal={true}
              path={IframePath.OS_UPDATES}
            />
          )}
          {biometricsNotEnabled && (
            <StatusDecisionListItem
              browser={props.browser}
              navigator={props.navigator}
              blocking={biometricsNotEnabledIsBlocking}
              message={t("decisions.biometricsWarning")}
              buttonText={t("actions.fix")}
              showModal={true}
              path={IframePath.OS_UPDATES}
            />
          )}
        </>
      )}
      <div className="decisions-button-container">
        <NotNowButton
          onClick={() => {
            props.endTransaction(props.transaction);
          }}
          blocked={blocking}
        />
        <RefreshButton {...props} />
      </div>
      <div className="decisions-button-container-mobile">
        <RefreshButton {...props} />
        <NotNowButton
          onClick={() => {
            props.endTransaction(props.transaction);
          }}
          blocked={blocking}
        />
      </div>
    </div>
  );
};

const RefreshButton = (props: DecisionsProps) => {
  const { t } = useTranslation();

  return (
    <Button
      size="large"
      variant="contained"
      className="button-refresh"
      onClick={props.refreshTransactionStatus}
      disabled={props.refreshingTransaction}
      endIcon={
        props.refreshingTransaction && (
          <CircularProgress
            style={{ color: "inherit", width: "1rem", height: "1rem" }}
          />
        )
      }
    >
      <Typography className={"install-text"}>
        {t("Token.nok.recheck")}
      </Typography>
    </Button>
  );
};

export default Decisions;
