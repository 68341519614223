/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type _token__transactionId__post_request = {
    action: _token__transactionId__post_request.action;
};
export namespace _token__transactionId__post_request {
    export enum action {
        JOIN_ORGANIZATION = 'join-organization',
        MERGE_SHADOWS = 'merge-shadows',
        SEND_MFA = 'send-mfa',
        CONFIRM_MFA = 'confirm-mfa',
        DENY_MFA = 'deny-mfa',
        TRUST_DEVICE_FOR_MFA = 'trust-device-for-mfa',
        RECHECK = 'recheck',
        REQUEST_JOIN_ORGANIZATION = 'request-join-organization',
        PROTOCOL_HANDLER_RECEIVED = 'protocol-handler-received',
    }
}

