/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DecisionMfa_credentials } from './DecisionMfa_credentials';
export type DecisionMfa = {
    /**
     * status of decision
     */
    status: DecisionMfa.status;
    error?: string;
    /**
     * will MFA be auto approved
     */
    autoApprove?: boolean;
    /**
     * is MFA auto approved
     */
    autoApproved?: boolean;
    /**
     * Indicates if MFA credential ID was found on the device
     */
    MfaCredIdFoundOnDevice?: boolean;
    /**
     * Indicates if this user always needs to verify on other device
     */
    AlwaysVerify?: boolean;
    /**
     * Indicates if this user confirmed through another device upon login (when AlwaysVerify is true)
     */
    Confirmed?: boolean;
    /**
     * Indicates if this user wants to trust this device
     */
    TrustThisDevice?: boolean;
    credentials?: DecisionMfa_credentials;
};
export namespace DecisionMfa {
    /**
     * status of decision
     */
    export enum status {
        OK = 'OK',
        WARNING = 'WARNING',
        BLOCKING = 'BLOCKING',
    }
}

