import React from "react";
import {
  // eslint-disable-next-line camelcase
  _token__transactionId__post_request,
  DeviceApi,
  Transaction,
} from "../API/XFA_DEVICE_API";
import { deviceApiConfig } from "../Config";
import { ErrorSection } from "../Token/sections/ErrorSection";
import useTransaction from "../Token/hooks/useTransaction";
// eslint-disable-next-line camelcase
import action = _token__transactionId__post_request.action;
import XFAIcon from "../../images/XFA_woordmerk_donkergrijs.svg";
import "../General/general.css";
import "./Mfa.css";
import TopBar from "../General/TopBar";
import { MfaHeader } from "./widgets/MfaHeader";
import { MfaButtons } from "./widgets/MfaButtons";
import { MfaWaitForConfirmation } from "./widgets/MfaWaitForConfirmation";
import { useLoginTransaction } from "./hooks/useLoginTransaction";
import { useTransactionPolling } from "./hooks/useTransactionPolling";
import { useTransactionStatusHandler } from "./hooks/useTransactionStatusHandler";
import { useRegister } from "./hooks/useRegister";
import { useRedirectToApplication } from "./hooks/useRedirectToApplication";
import { useClientApiContext } from "../Extension/ClientApiContext";

export interface MfaProps {
  navigator: any;
  browser: any;
}

const MfaExtension: React.FC<MfaProps> = (props: MfaProps) => {
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [waitForConfirmation, setWaitForConfirmation] =
    React.useState<boolean>(false);
  const [waitForEmailConfirmation, setWaitForEmailConfirmation] =
    React.useState<boolean>(false);
  const [enroll, setEnroll] = React.useState<boolean>(false);
  const [pendingAction, setPendingAction] = React.useState<boolean>(true);
  const { transaction, transactionError, updateTransaction } = useTransaction(
    props.navigator,
  );
  const { isExtensionInstalled } = useClientApiContext();
  const [didTapTrustButtons, setDidTapTrustButtons] =
    React.useState<boolean>(false);
  const deviceClient = new DeviceApi(deviceApiConfig);
  deviceClient.request.config.CREDENTIALS = "include";
  deviceClient.request.config.WITH_CREDENTIALS = true;

  const { handleRedirectToApplication } = useRedirectToApplication({
    setError,
  });

  const { register } = useRegister({
    transaction,
    setError,
    setWaitForConfirmation,
    setPendingAction,
    redirectToApplication: handleRedirectToApplication,
  });

  useLoginTransaction({
    transaction,
    error,
    setError,
    setPendingAction,
    redirectToApplication: handleRedirectToApplication,
    setWaitForConfirmation,
  });

  useTransactionPolling(
    transaction,
    error,
    waitForConfirmation,
    pendingAction,
    updateTransaction,
  );

  useTransactionStatusHandler({
    error,
    enroll,
    waitForConfirmation,
    transaction,
    register,
    handleRedirectToApplication,
    setError,
  });

  React.useEffect(() => {
    if (transactionError) {
      setError(transactionError);
    }
  }, [transactionError]);

  React.useEffect(() => {
    if (transaction === undefined) {
      return;
    }
    if (
      transaction.status === "SKIPPED" ||
      transaction.status === "UNSUPPORTED" ||
      transaction.status === "GRANTED"
    ) {
      setWaitForConfirmation(true);
      setPendingAction(true);
      return;
    }
    if (transaction.decisions?.mfa?.AlwaysVerify === true) {
      // AlwaysVerify flow
      setWaitForConfirmation(didTapTrustButtons);
      setPendingAction(didTapTrustButtons);
    }
  }, [transaction, didTapTrustButtons]);

  React.useEffect(() => {
    if (
      !transaction ||
      (transaction!.status !== Transaction.status.UNSUPPORTED &&
        transaction!.status !== Transaction.status.SKIPPED)
    ) {
      return;
    }
    if (waitForConfirmation) return;
    setWaitForConfirmation(true);
    deviceClient.default.postToken1(transaction?.transactionId!, {
      action: action.SEND_MFA,
    });
    // eslint-disable-next-line
  }, [transaction]);

  const enrollDevice = React.useCallback(() => {
    if (error !== undefined || error === "") return; //no need to refresh when there is an error
    setEnroll(true);
    deviceClient.default
      .postToken1(transaction?.transactionId!, {
        action: action.SEND_MFA,
      })
      .then((response) => {
        if (response) {
          const transaction = response as Transaction;

          //on auto approval, register the device immediately
          if (
            transaction?.decisions?.mfa?.autoApproved === true &&
            isExtensionInstalled
          ) {
            setWaitForConfirmation(false);
            setPendingAction(true);
            register();
          } else {
            setWaitForConfirmation(true);
          }
        } else {
          setWaitForConfirmation(true);
        }
      })
      .catch((error) => {
        setWaitForConfirmation(true);
        setError(error);
      });
    // eslint-disable-next-line
  }, [error, props, transaction]);

  const pending = waitForConfirmation || pendingAction;
  const firstMfaDevice =
    transaction?.decisions?.mfa?.credentials?.credentials === undefined ||
    transaction?.decisions?.mfa?.credentials?.credentials.filter((cred) => {
      return cred.deviceId !== transaction?.device_id;
    }).length === 0;

  return (
    <div className="root">
      <TopBar transaction={null} signIn={false} />
      <div className="content-container">
        <div className="content-token">
          <div className="general">
            <img src={XFAIcon} alt="XFAIcon" className="xfaIcon" />
            <MfaHeader
              waitForConfirmation={waitForConfirmation}
              pending={pending}
            />
            <div className="general">
              {error !== undefined ? (
                <ErrorSection error={error} />
              ) : pending ? (
                <MfaWaitForConfirmation
                  waitForConfirmation={waitForConfirmation}
                  waitForEmailConfirmation={waitForEmailConfirmation}
                  firstMfaDevice={firstMfaDevice}
                  onSendEmail={() => {
                    setWaitForConfirmation(true);
                    setWaitForEmailConfirmation(true);
                    deviceClient.default.postToken1(
                      transaction?.transactionId!,
                      {
                        action: action.SEND_MFA,
                      },
                      undefined,
                      undefined,
                      undefined,
                      true,
                    );
                  }}
                />
              ) : (
                <MfaButtons
                  onYesClick={() => {
                    setDidTapTrustButtons(true);
                    enrollDevice();
                  }}
                  onNoClick={() => {
                    setDidTapTrustButtons(true);
                    deviceClient.default
                      .postToken1(transaction?.transactionId!, {
                        action: action.SEND_MFA,
                      })
                      .then((response) => {
                        if (response) {
                          const transaction = response as Transaction;

                          if (
                            transaction?.decisions?.mfa?.autoApproved ===
                              true &&
                            isExtensionInstalled
                          ) {
                            setWaitForConfirmation(false);
                            setPendingAction(true);
                          } else {
                            setWaitForConfirmation(true);
                          }
                        } else {
                          setWaitForConfirmation(true);
                        }
                      })
                      .catch((error) => {
                        setWaitForConfirmation(true);
                        setError(error);
                      });
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MfaExtension;
