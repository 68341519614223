import {
  // eslint-disable-next-line camelcase
  _token__transactionId__post_request,
  DeviceApi,
  Transaction,
} from "../../API/XFA_DEVICE_API";
// eslint-disable-next-line camelcase
import action = _token__transactionId__post_request.action;
import { useTranslation } from "react-i18next";

export const useTrustDevice = ({
  transaction,
  setIsWaitingForConfirmation,
  setError,
  deviceClient,
}: {
  transaction: Transaction | undefined;
  setIsWaitingForConfirmation: (value: boolean) => void;
  setError: (error: any) => void;
  deviceClient: DeviceApi;
}) => {
  const { t } = useTranslation();
  const handleTrustDevice = () => {
    if (transaction?.transactionId) {
      deviceClient.default
        .postToken1(transaction.transactionId, {
          action: action.TRUST_DEVICE_FOR_MFA,
        })
        .then(() => {
          setIsWaitingForConfirmation(true);
        })
        .catch((error: any) => {
          setError(t("mfa.errors.trustingFailed"));
        });
    } else {
      setError("No transaction found");
    }
  };

  return handleTrustDevice;
};
